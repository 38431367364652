import { currentApp } from './primitive-selector'
import { getRiders } from 'e-submission/sagas/policy-payload/rider-selector'

export const quickQuote = (state) => {
  const basicPlanCode = currentApp('quickQuote.biProps.basicPlan.basicPlanCode')(state)
  const basicPlanName = currentApp('quickQuote.biProps.basicPlan.basicPlanName')(state)
  const category = currentApp('quickQuote.biProps.basicPlan.category')(state)
  const selectedRiders = getRiders(state)
  const riders = selectedRiders
    ? getRiders(state).map((rider) => ({
        code: rider.code,
        premium: rider.premium,
        selectedPlan: { planCode: rider.planCode },
        sumAssured: rider.sumAssured,
      }))
    : []
  const sumAssured = currentApp('quickQuote.biProps.sumAssured')(state)
  const totalPremium = currentApp('quickQuote.biProps.totalPremium')(state)
  const totalRiderPremium = currentApp('quickQuote.biProps.totalRiderPremium')(state)
  const loanPeriod = currentApp('quickQuote.biProps.loanPeriod')(state)
  const loanAmount = currentApp('quickQuote.biProps.loanAmount')(state)
  const paymentMethod = currentApp('quickQuote.biProps.paymentMethod')(state)
  const interestRate = currentApp('quickQuote.biProps.interestRate')(state)
  const sumAssuredContract = currentApp('quickQuote.biProps.sumAssuredContract')(state)
  return {
    basicPlanCode,
    basicPlanName,
    riders,
    sumAssured,
    totalPremium,
    totalRiderPremium,
    category,
    loanPeriod,
    loanAmount,
    paymentMethod,
    interestRate,
    sumAssuredContract,
  }
}
