import { createSelector } from 'reselect'
import { currentQQ } from './primitive-selector'
import { flow, toString } from 'lodash/fp'

const productCode = currentQQ('selectedDisplayProduct.code')
const basicPlanCode = currentQQ('selectedDisplayProduct.basicPlanCode')
const basicPlanName = currentQQ('selectedDisplayProduct.name')

const paymentPeriod = createSelector(
  [
    currentQQ('selectedDisplayProduct.paymentPeriod.type'),
    flow(currentQQ('selectedDisplayProduct.paymentPeriod.value'), toString),
  ],
  (type, value) => ({
    type,
    value,
  })
)

const coveragePeriod = createSelector(
  [
    currentQQ('selectedDisplayProduct.coveragePeriod.type'),
    flow(currentQQ('selectedDisplayProduct.coveragePeriod.value'), toString),
  ],
  (type, value) => ({
    type,
    value,
  })
)

export const product = createSelector(
  [productCode, basicPlanCode, paymentPeriod, coveragePeriod, basicPlanName],
  (code, basicPlanCode, paymentPeriod, coveragePeriod, basicPlanName) => ({
    code,
    basicPlanCode,
    paymentPeriod,
    coveragePeriod,
    basicPlanName,
  })
)
