import { constant, defaultTo, flow, get } from 'lodash/fp'
import { isGetChecked, isGetYes, isMatchRecursively, when } from './primitive-selector'

const getHealthQuestions = (insuredOrPayer) => {
  const wasRejected = get('wasRejected')(insuredOrPayer)
    ? get('wasRejected')(insuredOrPayer)
    : get('aura.healthFactor.wasRejected')(insuredOrPayer)
  const wasInjured = get('wasInjured')(insuredOrPayer)
    ? get('wasInjured')(insuredOrPayer)
    : get('aura.healthFactor.wasInjured')(insuredOrPayer)
  const wasSmoking = get('wasSmoking')(insuredOrPayer)
    ? get('wasSmoking')(insuredOrPayer)
    : get('aura.healthFactor.wasSmoking')(insuredOrPayer)
  const wasDrinking = get('wasDrinking')(insuredOrPayer)
    ? get('wasDrinking')(insuredOrPayer)
    : get('aura.healthFactor.wasDrinking')(insuredOrPayer)
  const useDrug = get('useDrug')(insuredOrPayer)
    ? get('useDrug')(insuredOrPayer)
    : get('aura.healthFactor.useDrug')(insuredOrPayer)
  const treatment = get('treatment')(insuredOrPayer)
    ? get('treatment')(insuredOrPayer)
    : get('aura.healthFactor.treatment')(insuredOrPayer)
  var fiveYearsHealthCheck = get('fiveYearsHealthCheck')(insuredOrPayer)
    ? get('fiveYearsHealthCheck')(insuredOrPayer)
    : get('aura.healthFactor.fiveYearsHealthCheck')(insuredOrPayer)
  const relativeHealthCheck = get('relativeHealthCheck')(insuredOrPayer)
    ? get('relativeHealthCheck')(insuredOrPayer)
    : get('aura.healthFactor.relativeHealthCheck')(insuredOrPayer)
  const healthSymptom = get('healthSymptom')(insuredOrPayer)
    ? get('healthSymptom')(insuredOrPayer)
    : get('aura.healthFactor.healthSymptom')(insuredOrPayer)
  const sixMonthSymptom = get('sixMonthSymptom')(insuredOrPayer)
    ? get('sixMonthSymptom')(insuredOrPayer)
    : get('aura.healthFactor.sixMonthSymptom')(insuredOrPayer)
  const isPregnant = get('isPregnant')(insuredOrPayer)
    ? get('isPregnant')(insuredOrPayer)
    : get('aura.healthFactor.isPregnant')(insuredOrPayer)
  const pregnancyComplication = get('pregnancyComplication')(insuredOrPayer)
    ? get('pregnancyComplication')(insuredOrPayer)
    : get('aura.healthFactor.pregnancyComplication')(insuredOrPayer)
  const healthDiagnosis = get('healthDiagnosis')(insuredOrPayer)
    ? get('healthDiagnosis')(insuredOrPayer)
    : get('aura.healthFactor.healthDiagnosis')(insuredOrPayer)
  const additionalDeclaration = get('additionalDeclaration')(insuredOrPayer)
    ? get('additionalDeclaration')(insuredOrPayer)
    : get('aura.healthFactor.additionalDeclaration')(insuredOrPayer)
  const auraQuestions = get('aura')(insuredOrPayer)
  const haveDiagnosis = get('haveDiagnosis')(insuredOrPayer)
    ? get('haveDiagnosis')(insuredOrPayer)
    : get('aura.healthFactor.haveDiagnosis')(insuredOrPayer)
  const mental = get('mental')(insuredOrPayer)
    ? get('mental')(insuredOrPayer)
    : get('aura.healthFactor.mental')(insuredOrPayer)
  const relativeHealthCheckS7 = get('relativeHealthCheckS7')(insuredOrPayer)
  const haveDiagnosisS7 = get('haveDiagnosisS7')(insuredOrPayer)
  const treatmentS7 = get('treatmentS7')(insuredOrPayer)
  const diagnosisMRTA = get('diagnosisMRTA')(insuredOrPayer)
  const diseaseMRTA = get('diseaseMRTA')(insuredOrPayer)
  const familyDiseaseMRTA = get('familyDiseaseMRTA')(insuredOrPayer)
  const treatmentMRTA = get('treatmentMRTA')(insuredOrPayer)
  const wasRejectedMRTA = get('wasRejectedMRTA')(insuredOrPayer)
  const otherInsuranceCompanyPA = get('otherInsuranceCompanyPA')(insuredOrPayer)
  const treatmentPA = get('treatmentPA')(insuredOrPayer)
  if (diagnosisMRTA) {
    fiveYearsHealthCheck = get('diagnosisMRTA')(insuredOrPayer)
  }
  return {
    wasRejected,
    wasInjured,
    wasSmoking,
    wasDrinking,
    useDrug,
    treatment,
    fiveYearsHealthCheck,
    relativeHealthCheck,
    healthSymptom,
    sixMonthSymptom,
    isPregnant,
    pregnancyComplication,
    healthDiagnosis,
    additionalDeclaration,
    auraQuestions,
    haveDiagnosis,
    mental,
    relativeHealthCheckS7,
    haveDiagnosisS7,
    treatmentS7,
    diagnosisMRTA,
    diseaseMRTA,
    familyDiseaseMRTA,
    treatmentMRTA,
    wasRejectedMRTA,
    otherInsuranceCompanyPA,
    treatmentPA,
  }
}

export const health = (insuredOrPayer) => {
  //console.log(insuredOrPayer)
  const weight = get('weight')(insuredOrPayer)
    ? get('weight')(insuredOrPayer)
    : get('aura.insuredWeight')(insuredOrPayer)
  const height = get('height')(insuredOrPayer)
    ? get('height')(insuredOrPayer)
    : get('aura.insuredHeight')(insuredOrPayer)
  const isWeightChangedRecently = flow(get('isWeightChangedRecently'), (val) =>
    typeof val === 'object' && val !== null ? ['increase', 'decrease'].includes(val.value) : val
  )(insuredOrPayer)
  let weightChangedRecently = when(
    () => isWeightChangedRecently,
    get('weightChangedRecently'),
    constant(undefined)
  )(insuredOrPayer)
  const isWeightChangedRecentlyVal = get('isWeightChangedRecently')(insuredOrPayer)
  if (
    typeof isWeightChangedRecentlyVal === 'object' &&
    isWeightChangedRecentlyVal !== null &&
    ['increase', 'decrease'].includes(isWeightChangedRecentlyVal.value)
  ) {
    weightChangedRecently = Object.assign({}, weightChangedRecently, { how: isWeightChangedRecentlyVal })
  }
  const healthQuestions = getHealthQuestions(insuredOrPayer)
  const wasRejected = get('wasRejectedMRTA')(insuredOrPayer)
    ? when(isGetChecked('wasRejectedMRTA'), constant('Y'), constant('N'))(healthQuestions)
    : when(isGetChecked('wasRejected'), constant('Y'), constant('N'))(healthQuestions)
  const wasSmoking = when(isGetYes('wasSmoking.stillSmoking.value'), constant('Y'), constant('N'))(healthQuestions)
  const cigarettesPerDay = flow(get('wasSmoking.amount'), defaultTo('0'))(healthQuestions)
  const wasDrinking = when(isGetChecked('wasDrinking'), constant('Y'), constant('N'))(healthQuestions)
  const unitsPerWeek = flow(get('wasDrinking.amount'), defaultTo('0'))(healthQuestions)
  const declared = when(
    isMatchRecursively({ checked: { value: 'yes' } }),
    constant('Y'),
    constant('N')
  )(healthQuestions)

  return {
    declared,
    weight,
    height,
    rejected: {
      wasRejected,
    },
    smoking: {
      wasSmoking,
      cigarettesPerDay,
    },
    alcohol: {
      wasDrinking,
      unitsPerWeek,
    },
    weightChangedRecently,
    isWeightChangedRecently,
    ...healthQuestions,
  }
}
