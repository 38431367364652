import { currentQQ } from 'e-submission/sagas/policy-payload/primitive-selector'
import _ from 'lodash'
import { filter, flatten, flow, get, map } from 'lodash/fp'
import { getToggles } from 'quick-quote/feature-toggles'

const getInsureAge = (insured) =>
  flow(get('age'), (ageObject) => {
    return ageObject.unit === 'year' ? `${ageObject.value}` : '0'
  })(insured)

const getMeaPlusRiderPlanCode = (rider, insured) => {
  const planCode = _.get(rider, 'selectedPlan.planCode', '')
  switch (planCode) {
    case 'MEA5': {
      const age = getInsureAge(insured)
      return age <= 10 ? 'MEA5J' : 'MEA5SE'
    }
    default:
      return planCode
  }
}
const getMeaPlusRider = (rider, insured) => {
  return _.set(rider, 'selectedPlan.planCode', getMeaPlusRiderPlanCode(rider, insured))
}

const getMEBRiderRiderPlanCode = (rider, insured) => {
  const planCode = _.get(rider, 'selectedPlan.planCode', '')
  switch (planCode) {
    case 'MEB1': {
      const age = getInsureAge(insured)
      return age <= 10 ? 'MEB1J' : 'MEB1S'
    }
    case 'MEB2': {
      const age = getInsureAge(insured)
      return age <= 10 ? 'MEB2J' : 'MEB2S'
    }
    default:
      return planCode
  }
}

const getMEBRider = (rider, insured) => {
  return _.set(rider, 'selectedPlan.planCode', getMEBRiderRiderPlanCode(rider, insured))
}
const riders = {
  MEAPlus: getMeaPlusRider,
  MEB: getMEBRider,
}
export const getRider = (insured) => {
  return (rider) => {
    if (getToggles().ENABLE_NEW_MEAPLUS_MEB_PLANCODE && riders[rider.code]) {
      return riders[rider.code](rider, insured)
    }
    return rider
  }
}
const getSelectedRiders = function(state) {
  const allRiders = currentQQ('coveragePlan.riders')(state)
  const riders = _.filter(allRiders, (rider) => rider.isSelectable && rider.isSelected)
  return riders.map(getRider(currentQQ('insured')(state)))
}
export const mapSelectedRiders = function(riders) {
  const mapRiders = riders.map((rider) => ({
    premium: rider.premium,
    sumAssured: rider.sumAssured,
    code: rider.code,
    planCode: rider.selectedPlan ? _.get(rider, 'selectedPlan.planCode', '') : '',
    yearsOfCoverage: rider.yearsOfCoverage,
    name: rider.name,
    aura: rider.aura,
  }))

  //TODO: make it more readable, extract to functions or remove nested anonymous functions
  const mapRiderModules = flow(
    map((rider) => {
      return flow(
        filter((v) => v.isSelected),
        map((riderModule) => {
          return {
            premium: riderModule.premium,
            sumAssured: riderModule.sumAssured || 0,
            code: rider.code,
            planCode: riderModule.code,
            yearsOfCoverage: rider.yearsOfCoverage,
            name: riderModule.code,
            aura: rider.aura,
          }
        })
      )(get('selectedPlan.modules')(rider))
    }),
    flatten
  )(riders)
  let mapRiderPA = []

  const PARider = riders.find((element) => element.code == 'PARIDER')
  if (PARider) {
    mapRiderPA = PARider.selectedPlan.packageDetail.map((riderPackage) => {
      return {
        premium: 0,
        sumAssured: riderPackage.value || 0,
        code: riderPackage.code,
        planCode: riderPackage.planCode || '',
        yearsOfCoverage: 0,
        name: riderPackage.text,
        aura: {},
      }
    })
  }
  return _.unionWith(mapRiders, mapRiderModules, mapRiderPA, _.isEqual)
}
export const getRiders = (state) => {
  const riders = getSelectedRiders(state)
  return mapSelectedRiders(riders)
}
export const mergeRiderArray = (riders, anotherRiders) => {
  if (!_.isArray(riders) || !_.isArray(anotherRiders)) {
    return []
  }
  return riders.map((rider) =>
    _.merge({ ...anotherRiders.find((anotherRider) => anotherRider.code === rider.code) }, rider)
  )
}
export const getOccupationForRidersInTypeArray = (occupationForRiders) => {
  return Object.keys(occupationForRiders).map((key) => ({
    code: key,
    occupationFactor: occupationForRiders[key],
  }))
}
