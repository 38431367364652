// @flow
import _ from 'lodash'
import { all, call, put, select, race, take, takeLatest } from 'redux-saga/effects'
import { getIdentityUser, isAllowIproMenu } from 'identity/selectors'
import { getCurrentApp, getCurrentAppId } from 'e-submission/apps/selectors'
import { getSfReference } from 'e-submission/domain/data-model/form/selectors'
import { updateIProData } from 'e-submission/domain/service/opty'
import { setAppValue } from 'e-submission/apps/actions'

import {
  UPDATE_OPTY_E_APP_CREATE,
  UPDATE_OPTY_E_APP,
  UPDATE_OPTY_KYC,
  UPDATE_OPTY_PAYMENT,
  sfReference,
} from 'quick-quote/opty/actions'

export const onUpdateOpty = function*(stageName) {
  const user = yield select(getIdentityUser)
  const applicationId = yield select(getCurrentAppId)

  const isAllowed = yield select(isAllowIproMenu)

  if (!applicationId || !isAllowed) {
    return
  }

  if (stageName === 'CREATE') {
    stageName = 'E-APP'
  } else {
    const { failed } = yield race({
      success: take('SAVE_APPLICATION_SUCCESS'),
      failed: take('SAVE_APPLICATION_FAIL'),
    })

    if (failed) {
      return
    }
  }

  try {
    const updateIProDataresp = yield call(updateIProData, user, applicationId, stageName)
    const app = yield select(getCurrentApp)
    const sfRef = getSfReference(app)

    const updatedAccId = _.get(updateIProDataresp, 'ProspectResult.id', '')
    const updatedOptyId = _.get(updateIProDataresp, 'OpportunityResult[0].id', '')

    if (!!updatedAccId && !!updatedOptyId) {
      const sfRefData = {
        ...sfRef,
        accId: updatedAccId,
        optyId: updatedOptyId,
      }
      yield put(sfReference(sfRefData))
      yield put(setAppValue('sfReference', sfRefData))
    }
  } catch (error) {
    console.error('Failed to Update IPro Data')
  }
}

export default function* watchers() {
  yield all([
    takeLatest(UPDATE_OPTY_E_APP_CREATE, onUpdateOpty, 'CREATE'),
    takeLatest(UPDATE_OPTY_E_APP, onUpdateOpty, 'E-APP'),
    takeLatest(UPDATE_OPTY_KYC, onUpdateOpty, 'KYC'),
    takeLatest(UPDATE_OPTY_PAYMENT, onUpdateOpty, 'PAYMENT'),
  ])
}

export const sagas = watchers
