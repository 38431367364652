import { getRiskiestOccupationFactorForRiders } from 'core/service/insured'
import { getDisplayProductQuery, getNatureOfDutyCodes } from 'e-submission/sagas/policy-payload/coverageplan-selector'
import {
  getOccupationForRidersInTypeArray,
  getRiders,
  mergeRiderArray,
} from 'e-submission/sagas/policy-payload/rider-selector'
import _ from 'lodash'
import { modelFactorToPaymentMode } from './coverageplan'
import { currentQQ } from './primitive-selector'

export const coveragePlan = async (state) => {
  const sumAssured = _.toString(currentQQ('coveragePlan.selectedBasicPlan.sumAssured')(state))
  const regularPremium = _.toString(currentQQ('coveragePlan.selectedBasicPlan.regularPremium')(state))
  const regularTopUp = _.toString(currentQQ('coveragePlan.selectedBasicPlan.regularTopUp')(state))
  const lumpSumTopUp = _.toString(currentQQ('coveragePlan.selectedBasicPlan.lumpSumTopUp')(state))
  const totalPremium = _.toString(currentQQ('coveragePlan.totalPremium.value')(state))
  const selectedModelFactorID = currentQQ('coveragePlan.selectedModelFactorID')(state)
  const paymentMode = modelFactorToPaymentMode(selectedModelFactorID)
  const funds = currentQQ('funds')(state)
  const riders = getRiders(state)
  const displayProductQuery = getDisplayProductQuery(state)
  const natureOfDutyCodes = getNatureOfDutyCodes(state)
  const riskiestOccupationFactorForRiders = await getRiskiestOccupationFactorForRiders(
    displayProductQuery,
    natureOfDutyCodes,
    riders
  )
  return {
    basicPlan: {
      sumAssured,
      regularPremium,
      regularTopUp,
      lumpSumTopUp,
      totalPremium,
    },
    riders: mergeRiderArray(riders, getOccupationForRidersInTypeArray(riskiestOccupationFactorForRiders)),
    paymentMode,
    funds,
  }
}
