import _ from 'lodash'

import { getToggles } from 'quick-quote/feature-toggles'

import { currentQQ } from './primitive-selector'

export const product = (state) => {
  const code = currentQQ('selectedDisplayProduct.code')(state)
  const basicPlanCode = currentQQ('selectedDisplayProduct.basicPlanCode')(state)
  const basicPlanName = currentQQ('selectedDisplayProduct.basicPlanName')(state)
  const category = currentQQ('selectedDisplayProduct.category')(state)
  const paymentPeriodType = currentQQ('selectedDisplayProduct.paymentPeriod.type')(state)
  const paymentPeriodValue = currentQQ('selectedDisplayProduct.paymentPeriod.value')(state)
  const coveragePeriodType = currentQQ('selectedDisplayProduct.coveragePeriod.type')(state)
  const coveragePeriodValue = currentQQ('selectedDisplayProduct.coveragePeriod.value')(state)
  const saleSheet = currentQQ('selectedDisplayProduct.saleSheet')(state)
  const selectedDisplayProduct = {
    code,
    basicPlanCode,
    basicPlanName,
    category,
    paymentPeriod: {
      type: paymentPeriodType,
      value: _.toString(paymentPeriodValue),
    },
    coveragePeriod: {
      type: coveragePeriodType,
      value: _.toString(coveragePeriodValue),
    },
  }
  if (getToggles().ENABLE_SALE_SHEET_IN_EMAIL) {
    selectedDisplayProduct.saleSheet = saleSheet
  }
  return selectedDisplayProduct
}
