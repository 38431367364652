import _ from 'lodash'
import { ECBR_PAYER_TYPE } from 'e-submission/domain/data-model/constants'

const mappingPayerInfo = (insured, payer, payerInfo) => {
  let results = {}
  const payerTypeText = {
    SPOUSE: 'Couple',
    CHILD: 'Child',
  }
  switch (payerInfo?.payerType) {
    case ECBR_PAYER_TYPE.INSURED:
      results.payerFirstName = insured?.firstName
      results.payerLastName = insured?.lastName
      results.payerRelation = '01'
      results.payerCitizenID =
        insured?.idType?.value === 'CITIZEN_ID' ? insured?.idNo?.citizenId : insured?.idNo?.passport
      results.payerType = 'insured'
      break
    case ECBR_PAYER_TYPE.PAYER:
      results.payerFirstName = payer?.firstName
      results.payerLastName = payer?.lastName
      results.payerRelation = '02'
      results.payerCitizenID = payer?.idType?.value === 'CITIZEN_ID' ? payer?.idNo?.citizenId : payer?.idNo?.passport
      results.payerType = 'owner'
      break
    case ECBR_PAYER_TYPE.OTHER:
      results.payerFirstName = payerInfo?.firstName
      results.payerLastName = payerInfo?.lastName
      results.payerRelation = '03'
      results.payerCitizenID =
        payerInfo?.idType?.value === 'CITIZEN_ID' ? payerInfo?.idNo?.citizenId : payerInfo?.idNo?.passport
      results.payerType =
        payerInfo?.relations?.value === 'OTHERS'
          ? payerInfo?.relations?.others
          : payerTypeText[payerInfo?.relations?.value]
      break

    default:
      results = {}
      break
  }

  return results
}

export const paymentLinkToCustomer = (policyId, app) => {
  const quickpayMethod = _.get(app, 'quickpayLink.sendLink.method', 'sms')
  const insured = _.get(app, 'insured')
  const payer = _.get(app, 'payer')
  const payerInfo = _.get(app, 'payerInfo')

  return {
    policyNo: policyId,
    citizenId: _.get(app, 'insured.idNo.citizenId') || _.get(app, 'insured.idNo.passport') || '',
    planCode: _.get(app, 'quickQuote.selectedDisplayProduct.basicPlanCode'),
    productName: _.get(app, 'quickQuote.selectedDisplayProduct.name', ''),
    insuredFirstName: _.get(app, 'insured.firstName'),
    insuredLastName: _.get(app, 'insured.lastName'),
    email: quickpayMethod === 'email' ? _.get(app, 'quickpayLink.sendLink.textInput', '') : '',
    premium: _.get(app, 'receipts[2].totalFirstPremiumPayment', ''),
    phone: quickpayMethod === 'sms' ? _.get(app, 'quickpayLink.sendLink.textInput', '') : '',
    agentId: _.get(app, 'quickQuote.agent.advisorId', ''),
    channelPrefix: 'AZQ',
    policyStatus: '00',
    sendMethod: quickpayMethod.toUpperCase(),
    ...mappingPayerInfo(insured, payer, payerInfo),
  }
}

export const paymentLinkToCustomerCCPA = (policyId, app) => {
  const ccpaInfo = _.get(app, 'CCPA')
  const ccpaMethod = _.get(ccpaInfo, 'sendLink.method.value', '')
  const planCode = _.get(app, 'quickQuote.selectedDisplayProduct.basicPlanCode')
  let citizenId = _.get(ccpaInfo, 'idNo.citizenId', '')
  if (_.get(ccpaInfo, 'idNo.idType.value', '') === 'PASSPORT') {
    citizenId = _.get(ccpaInfo, 'idNo.passport', '')
  }
  return {
    policyNo: policyId,
    citizenId,
    planCode,
    insuredFirstName: _.get(app, 'insured.firstName'),
    insuredLastName: _.get(app, 'insured.lastName'),
    email: ccpaMethod === 'email' ? _.get(ccpaInfo, 'sendLink.email', '') : '',
    paymentType: '01',
    phone: ccpaMethod === 'sms' ? _.get(ccpaInfo, 'sendLink.sms', '') : '',
    channelPrefix: 'AZQ',
    sendMethod: ccpaMethod.toUpperCase(),
    cardHolderTitle: _.get(ccpaInfo, 'title.text', ''),
    cardHolderFirstName: _.get(ccpaInfo, 'firstName', ''),
    cardHolderLastname: _.get(ccpaInfo, 'lastName', ''),
  }
}
