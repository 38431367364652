import { take, put, race } from 'redux-saga/effects'
import { showDialog, hideDialog } from 'e-submission/apps/actions'

export function* confirmDialog(message = {}, dialogProps = {}, cssClass = '') {
  yield put(showDialog({ name: 'Confirmation', message, ...dialogProps, cssClass: cssClass }))

  const { yes } = yield race({
    yes: take('CONFIRM_YES'),
    no: take('CONFIRM_NO'),
  })

  yield put(hideDialog())
  return !!yes
}

export function* confirmDialogEDA(message = {}, dialogProps = {}, cssClass = '') {
  yield put(showDialog({ name: 'ConfirmationEDA', message, ...dialogProps, cssClass: cssClass }))

  const { yes } = yield race({
    yes: take('CONFIRM_YES'),
    no: take('CONFIRM_NO'),
  })

  yield put(hideDialog())
  return !!yes
}
