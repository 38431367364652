import { isEmpty } from 'lodash/fp'

const IMAGE_WIDTH = 300
const IMAGE_HEIGHT = 60

export default () => {
  let data, onSign
  const destroy = () => {
    document.removeEventListener('SigCaptureWeb_SignResponse', onSign, false)
    document.documentElement.removeChild(data)
  }

  const waitSign = ({ firstName, lastName, email } = {}) =>
    new Promise((resolve, reject) => {
      const message = {
        imageX: IMAGE_WIDTH,
        imageY: IMAGE_HEIGHT,
        firstName: firstName || '',
        lastName: lastName || '',
        eMail: email || '',
        location: '',
        imageFormat: 1,
        imageTransparency: false,
        imageScaling: false,
        maxUpScalePercent: 100,
        rawDataFormat: 'ENC',
        minSigPoints: 0,
        penThickness: 3,
        penColor: '#000000',
      }
      onSign = (event) => {
        const json = event.target.getAttribute('SigCaptureWeb_msgAttri')
        const { imageData, isSigned, errorMsg } = JSON.parse(json)

        if (isEmpty(errorMsg)) {
          if (isSigned) {
            resolve(imageData)
          }
        } else {
          reject(errorMsg)
        }
      }

      const evt = document.createEvent('Events')
      evt.initEvent('SigCaptureWeb_SignStartEvent', true, false)
      document.addEventListener('SigCaptureWeb_SignResponse', onSign, false)
      data = document.createElement('SigCaptureWeb_ExtnDataElem')
      data.setAttribute('SigCaptureWeb_MsgAttribute', JSON.stringify(message))
      document.documentElement.appendChild(data)

      data.dispatchEvent(evt)
    })

  return {
    waitSign,
    destroy,
  }
}
