import _ from 'lodash'
import { get, isNil } from 'lodash/fp'
import store from './store'
import { runSaga } from 'redux-saga'
import { put, select, takeLatest } from 'redux-saga/effects'

import rootSaga from 'e-submission/sagas'
import { setReplicationStatus } from 'e-submission/apps/actions'
import { REPLICATION_STATUS } from 'e-submission/domain/data-model/constants'
import { emitter, isCordova } from 'e-submission/utils'
import { getIdentityUser } from 'identity/selectors'

export const createSagaMiddleware = (store) => {
  const sagaEmitter = emitter()
  const emit = (action) => {
    sagaEmitter.emit(action)
    if (action.type === 'SHOW_LOADING') {
      return new Promise((resolve) => {
        _.delay(() => resolve(action), 300)
      })
    }
  }

  const dispatch = store.dispatch
  store.dispatch = (action) => {
    dispatch(action)
    return emit(action)
  }

  return (rootSaga, ...args) =>
    runSaga(
      {
        subscribe: sagaEmitter.subscribe,
        dispatch: (...args) => store.dispatch(...args),
        getState: () => store.getState(),
      },
      rootSaga,
      ...args
    )
}

export const initEsubmission = (quickQuoteStore, history) => {
  const getState = store.getState
  store.getState = () => {
    const quickQuoteState = quickQuoteStore.getState()
    return {
      ...getState(),
      occupations: quickQuoteState.occupations,
      identity: quickQuoteState.identity,
      modelFactors: quickQuoteState.modelFactors,
      riders: quickQuoteState.riders,
      allowedAgentTypes: quickQuoteState.allowedAgentTypes,
      products: quickQuoteState.products,
      productDisplays: quickQuoteState.viewConfiguration.productList,
    }
  }

  const dispatch = store.dispatch
  store.dispatch = (action) => {
    dispatch(action)
    quickQuoteStore.dispatch(action)
  }

  createSagaMiddleware(store)(rootSaga, history)
  /* istanbul ignore next */
  createSagaMiddleware(quickQuoteStore)(function*() {
    const productChanged = ({ type }) => _.startsWith(type, 'PRODUCT_SELECTION')
    const getUserId = get('profile.sub')
    const isSameUser = (user) => {
      const oldUserId = window.localStorage['_userId']
      const currentUserId = getUserId(user)

      const isSame = oldUserId === currentUserId

      return isNil(oldUserId) || isSame
    }

    yield [
      takeLatest(productChanged, function*() {
        dispatch({ type: 'RESET_CURRENT_APP' })
      }),
      takeLatest('USER_FOUND', function*(action) {
        if (isCordova) {
          try {
            const user = yield select(getIdentityUser)

            try {
              if (!isSameUser(user)) {
                yield put(setReplicationStatus(REPLICATION_STATUS.ACTIVE))
              }

              window.localStorage['_userId'] = getUserId(user)
            } catch (err) {}
          } catch (err) {}
        }
      }),
    ]
  })

  store.dispatch({ type: 'INITIAL_E_SUBMISSION_REQUEST' })
}
