export const modelFactorToPaymentMode = (modelFactorID) => {
  switch (modelFactorID) {
    case 'model-factor_1':
    case 'model-factor_5':
    case 'model-factor_6':
      return 'A'
    case 'model-factor_2':
      return 'S'
    case 'model-factor_3':
      return 'M'
    case 'model-factor_4':
      return 'Q'
  }
}
