import { getAgeFromBirthDate } from 'core/service/insured/birthdate'
import _ from 'lodash'
import { constant, defaultTo, find, flow, get, isEqual, isUndefined, map } from 'lodash/fp'
import { getToggles } from 'quick-quote/feature-toggles'
import {
  getCurrentAppId,
  getInsuredMainOccupation,
  getInsuredOtherOccupation,
  isForeignerSelection,
} from '../../apps/selectors'
import { addPrefixToAmphur, addPrefixToTambol } from '../utils'
import { health } from './health-selector'
import { currentApp, currentQQ, fromBuddhistToChristian, when } from './primitive-selector'

export const insured = (state) => {
  const title = currentApp('insured.title.text')(state)
  const titleValue = currentApp('insured.title.value')(state)
  const firstName = currentApp('insured.firstName')(state)
  const lastName = currentApp('insured.lastName')(state)
  const gender = currentQQ('insured.gender')(state)
  const idNo = currentApp('insured.idNo')(state)
  const idType = currentApp('insured.idType')(state)
  const nationality = currentApp('insured.nationality')(state)
  const maritalStatus = currentApp('insured.maritalStatus.value')(state)
  const age = flow(currentQQ('insured.age'), (ageObject) => {
    return ageObject.unit === 'year' ? `${ageObject.value}` : '0'
  })(state)
  const birthdate = flow(currentQQ('insured.birthdate.value'), fromBuddhistToChristian)(state)
  const mainOccupationIncomePerMonth = currentApp('insured.mainOccupationIncomePerMonth')(state)
  const otherOccupationIncomePerMonth = currentApp('insured.otherOccupationIncomePerMonth')(state)
  const healthInfo = health(currentApp('insured')(state))

  const hasOtherPolicy = currentApp('insured.hasOtherPolicy.value')(state)

  const educationText = currentApp('insured.education.text')(state)
  const educationValue = currentApp('insured.education.value')(state)
  const noOfDependence = currentApp('insured.noOfDependence.value')(state)

  const transformOtherPolicy = (policy) => {
    return {
      companyName: get('companyName')(policy),
      policyNumber: get('policyNumber')(policy),
      coverageType: get('coverageType.value')(policy),
      sumAssured: get('sumAssured')(policy),
      isCurrentlyEnforced: get('isCurrentlyEnforced.value')(policy),
    }
  }

  const getCurrentAddress = () => {
    const currentAddress = {
      numberMoobanArkan: currentAddressNumberMoobanArkan,
      province: currentAddressProvince,
      amphur: currentAddressAmphur,
      tambol: currentAddressTambol,
      postCode: currentAddressPostCode,
    }

    return currentAddress
  }

  const getOfficeAddress = () => {
    const officeAddress = currentApp('insured.officeAddress.isSameAsCurrentAddress')(state)
      ? {
          name: officeName,
          ...getCurrentAddress(),
        }
      : {
          name: officeName,
          numberMoobanArkan: officeAddressNumberMoobanArkan,
          province: officeAddressProvince,
          amphur: officeAddressAmphur,
          tambol: officeAddressTambol,
          postCode: officeAddressPostCode,
        }

    return officeAddress
  }

  const getSpouseCurrentAddress = () => {
    const currentSpouseAddressProvince = currentApp('insured.foreigner.spouse.address.province')(state)
    const spouseCurrentAddress = {
      numberMoobanArkan: currentApp('insured.foreigner.spouse.address.numberMoobanArkan')(state),
      province: currentSpouseAddressProvince,
      amphur: addPrefixToAmphur(
        currentApp('insured.foreigner.spouse.address.amphur.text')(state),
        currentSpouseAddressProvince
      ),
      tambol: addPrefixToTambol(
        currentApp('insured.foreigner.spouse.address.tambol.text')(state),
        currentSpouseAddressProvince
      ),
      postCode: currentApp('insured.foreigner.spouse.address.postCode.value')(state),
    }

    return spouseCurrentAddress
  }

  const getSpouseOfficeAddress = () => {
    const currentSpouseOfficeAddressProvince = currentApp('insured.foreigner.spouse.officeAddress.province')(state)
    const spouseUseCurrentInsuredAddress = currentApp('insured.foreigner.spouse.address.useCurrentInsuredAddress')(
      state
    )
    const spouseUseSameAsCurrentAddress = currentApp('insured.foreigner.spouse.officeAddress.isSameAsCurrentAddress')(
      state
    )
    const officeAddress = spouseUseSameAsCurrentAddress
      ? {
          ...getSpouseCurrentAddress(),
        }
      : {
          numberMoobanArkan: currentApp('insured.foreigner.spouse.officeAddress.numberMoobanArkan')(state),
          province: currentSpouseOfficeAddressProvince,
          amphur: addPrefixToAmphur(
            currentApp('insured.foreigner.spouse.officeAddress.amphur.text')(state),
            currentSpouseOfficeAddressProvince
          ),
          tambol: addPrefixToTambol(
            currentApp('insured.foreigner.spouse.officeAddress.tambol.text')(state),
            currentSpouseOfficeAddressProvince
          ),
          postCode: currentApp('insured.foreigner.spouse.officeAddress.postCode.value')(state),
        }
    const spouseOfficeAddress =
      spouseUseCurrentInsuredAddress && spouseUseSameAsCurrentAddress ? { ...getCurrentAddress() } : officeAddress
    return spouseOfficeAddress
  }

  const otherPolicy = flow(currentApp('insured.otherPolicy'), defaultTo([]), map(transformOtherPolicy))(state)

  const email = currentApp('insured.email')(state)
  const mobileNumber = currentApp('insured.mobileNumber')(state)
  const telephoneNumber = currentApp('insured.telephoneNumber')(state)
  const currentAddressNumberMoobanArkan = currentApp('insured.currentAddress.numberMoobanArkan')(state)
  const currentAddressProvince = currentApp('insured.currentAddress.province')(state)
  const currentAddressAmphur = addPrefixToAmphur(
    currentApp('insured.currentAddress.amphur.text')(state),
    currentAddressProvince
  )
  const currentAddressTambol = addPrefixToTambol(
    currentApp('insured.currentAddress.tambol.text')(state),
    currentAddressProvince
  )
  const currentAddressPostCode = currentApp('insured.currentAddress.postCode.value')(state)
  const registeredAddressNumberMoobanArkan = currentApp('insured.registeredAddress.numberMoobanArkan')(state)
  const registeredAddressProvince = currentApp('insured.registeredAddress.province')(state)
  const registeredAddressAmphur = addPrefixToAmphur(
    currentApp('insured.registeredAddress.amphur.text')(state),
    registeredAddressProvince
  )
  const registeredAddressTambol = addPrefixToTambol(
    currentApp('insured.registeredAddress.tambol.text')(state),
    registeredAddressProvince
  )
  const registeredAddressPostCode = currentApp('insured.registeredAddress.postCode.value')(state)
  const officeName = currentApp('insured.officeName')(state)
  const officeAddressNumberMoobanArkan = currentApp('insured.officeAddress.numberMoobanArkan')(state)
  const officeAddressProvince = currentApp('insured.officeAddress.province')(state)
  const officeAddressAmphur = addPrefixToAmphur(
    currentApp('insured.officeAddress.amphur.text')(state),
    officeAddressProvince
  )
  const officeAddressTambol = addPrefixToTambol(
    currentApp('insured.officeAddress.tambol.text')(state),
    officeAddressProvince
  )
  const officeAddressPostCode = currentApp('insured.officeAddress.postCode.value')(state)
  const motorcycleUsage = flow(
    currentApp('insured.motorcycleUsage.value'),
    when(isEqual('yes'), constant('Y'), constant('N'))
  )(state)
  const idKey = flow(
    find(idType.value),
    when(isUndefined, constant(''), get(idType.value))
  )([{ CITIZEN_ID: 'citizenId' }, { GOV_ID: 'citizenId' }, { PASSPORT: 'passport' }])
  const occupation = getInsuredMainOccupation(state)
  const otherOccupation = getInsuredOtherOccupation(state)
  const dividend = currentApp('insured.dividend')(state)
  const taxCertConsent = currentApp('insured.taxCertConsent')(state)
  const taxCertId = currentApp('insured.idNo.taxId')(state)

  const result = {
    id: idNo[idKey],
    idType,
    title,
    titleValue,
    firstName,
    lastName,
    nationality,
    educationText,
    educationValue,
    noOfDependence,
    gender,
    maritalStatus,
    age,
    birthdate,
    motorcycleUsage,
    healthInfo,
    hasOtherPolicy,
    otherPolicy,
    dividend,
    contactInfo: {
      email,
      mobileNumber,
      telephoneNumber,
    },
    address: {
      current: getCurrentAddress(),
      registration: {
        numberMoobanArkan: registeredAddressNumberMoobanArkan,
        province: registeredAddressProvince,
        amphur: registeredAddressAmphur,
        tambol: registeredAddressTambol,
        postCode: registeredAddressPostCode,
      },
      office: getOfficeAddress(),
    },
    occupation: {
      occupationClass: _.get(occupation, 'occupationClass'),
      natureOfDutyCode: _.get(occupation, 'natureOfDutyCode'),
      incomePerMonth: mainOccupationIncomePerMonth,
      ..._.get(occupation, 'th'),
    },
    otherOccupation: {
      occupationClass: _.get(otherOccupation, 'occupationClass'),
      natureOfDutyCode: _.get(otherOccupation, 'natureOfDutyCode'),
      incomePerMonth: otherOccupationIncomePerMonth,
      ..._.get(otherOccupation, 'th'),
    },
    taxCertConsent,
    taxCertId,
  }
  if (getToggles().ENABLE_JUVENILE_PURCHASING_FORM) {
    result.juvenilePurchasing = currentApp('insured.juvenilePurchasing')(state)
  }
  if (getToggles().ENABLE_FOREIGNER_FORM && isForeignerSelection(state)) {
    const foreignerInfo = currentApp('insured.foreigner')(state)
    const spouseBirthdate = currentApp('insured.foreigner.spouse.birthdate')(state)

    const spouseAnnualIncome = currentApp('insured.foreigner.spouse.annualIncome')(state)
    const spouseOccupation = currentApp('insured.foreigner.spouse.occupation')(state)
    const spouseHowLongHaveYouBeenMarried = currentApp('insured.foreigner.spouse.howLongHaveYouBeenMarried')(state)
    const spouseKidData = currentApp('insured.foreigner.spouse.kid.dataChildren')(state)

    const spouseInfo = currentApp('insured.foreigner.spouse')(state)
    result.foreigner =
      foreignerInfo && spouseInfo
        ? Object.assign(foreignerInfo, {
            spouse: {
              annualIncome: spouseAnnualIncome,
              occupation: spouseOccupation,
              howLongHaveYouBeenMarried: spouseHowLongHaveYouBeenMarried,
              age: getAgeFromBirthDate(spouseBirthdate),
              address: getSpouseCurrentAddress(),
              officeAddress: getSpouseOfficeAddress(),
              kid: spouseKidData
                ? {
                    dataChildren: spouseKidData.map((kidData) => {
                      const kidBirthdate = kidData.birthdate
                      const kidAge = getAgeFromBirthDate(kidBirthdate)
                      return {
                        birthdate: kidBirthdate,
                        childrenAge: kidAge,
                      }
                    }),
                  }
                : null,
            },
          })
        : foreignerInfo
  }
  if (getToggles().ALLOW_AURA_QUESTIONARE) {
    result.auraSessionId = getCurrentAppId(state).split('_')[2]
  }
  if (getToggles().ENABLE_2_LINES_ADDRESS) {
    result.address.current.numberMoobanArkan2 = currentApp('insured.currentAddress.numberMoobanArkan2')(state)
    result.address.office.numberMoobanArkan2 = currentApp('insured.officeAddress.numberMoobanArkan2')(state)
    result.address.registration.numberMoobanArkan2 = currentApp('insured.registeredAddress.numberMoobanArkan2')(state)
  }
  if (getToggles().ENABLE_E_POLICY) {
    result.isPolicyDeliveryByEmail = currentApp('insured.isPolicyDeliveryByEmail.value')(state)
  }
  if (getToggles().ENABLE_TYPE_OF_BUSINESS) {
    result.occupation.typeOfBusiness = currentApp('insured.typeOfBusiness')(state)
  }

  const insured = currentApp('insured')(state)
  if (_.get(insured, 'CRS')) result.CRS = currentApp('insured.CRS')(state)
  if (_.get(insured, 'placeOfBirthCity')) result.placeOfBirthCity = currentApp('insured.placeOfBirthCity')(state)
  if (_.get(insured, 'placeOfBirthCountry'))
    result.placeOfBirthCountry = currentApp('insured.placeOfBirthCountry')(state)

  return result
}
