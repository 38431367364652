'use strict'
import { defaultTo, flow, get, head, map, toUpper } from 'lodash/fp'
// import moment from 'moment'
import { getAgeFromBirthDate } from 'core/service/insured/birthdate'
import { currentApp, fromBuddhistToChristian } from './primitive-selector'
import { addPrefixToAmphur, addPrefixToTambol } from '../utils'
import _ from 'lodash'
import { getToggles } from 'quick-quote/feature-toggles'

export const beneficiaries = (state) => flow(currentApp('beneficiary'), defaultTo([]), map(beneficiary))(state)

const beneficiary = (beneficiaryItem) => {
  const birthdate = flow(get('birthdate'), fromBuddhistToChristian)(beneficiaryItem)
  const province = get('beneficiaryAddress.province')(beneficiaryItem)
  const beneficiaryFname = _.get(beneficiaryItem, 'firstName')
  const beneficiaryLname = _.get(beneficiaryItem, 'lastName')
  const result = {
    firstName: beneficiaryFname.trim(),
    lastName: beneficiaryLname.trim(),
    title: get('title.text')(beneficiaryItem),
    gender: flow(get('sex.value'), head, toUpper)(beneficiaryItem),
    birthdate,
    age: getAgeFromBirthDate(birthdate),
    address: {
      numberMoobanArkan: get('beneficiaryAddress.numberMoobanArkan')(beneficiaryItem),
      amphur: addPrefixToAmphur(get('beneficiaryAddress.amphur.text')(beneficiaryItem), province),
      tambol: addPrefixToTambol(get('beneficiaryAddress.tambol.text')(beneficiaryItem), province),
      province: get('beneficiaryAddress.province')(beneficiaryItem),
      postCode: get('beneficiaryAddress.postCode.value')(beneficiaryItem),
    },
    allocation: get('allocation')(beneficiaryItem),
    relations: get('relations')(beneficiaryItem),
  }

  if (getToggles().ENABLE_2_LINES_ADDRESS) {
    return _.set(result, 'address.numberMoobanArkan2', get('beneficiaryAddress.numberMoobanArkan2')(beneficiaryItem))
  }

  return result
}
