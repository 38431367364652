import _getOr from 'lodash/fp/getOr'
import moment from 'moment'
import { titleEkyc } from '../../domain/data-model/constants'

export const mappingCaseKeeper = (app, insuredOrPayer, isReuse = false) => {
  const insuredInfo = _getOr({}, ['quickQuote', 'insured'], app)
  const payerInfo = _getOr({}, ['quickQuote', 'insured', 'payer'], app)
  const sendLinkEkycDetail = _getOr({}, ['ekycInfo', 'sendLink'], app)
  const title = _getOr('other', [insuredOrPayer, 'title', 'value'], app)
  const method = _getOr('', ['method'], sendLinkEkycDetail)
  const notifyType = isReuse ? { notifyType: 'none' } : { notifyType: method }
  const mapPersonalInfo = {
    insured: { ...insuredInfo, esub: _getOr({}, ['insured'], app) },
    payer: { ...payerInfo, esub: _getOr({}, ['payer'], app) },
  }

  const proprietorsObject = {
    firstName: _getOr('', [insuredOrPayer, 'esub', 'firstName'], mapPersonalInfo),
    lastName: _getOr('', [insuredOrPayer, 'esub', 'lastName'], mapPersonalInfo),
    proprietorType: insuredOrPayer,
    ...notifyType,
    countryCode: 'TH',
    citizenId: _getOr('', [insuredOrPayer, 'esub', 'idNo', 'citizenId'], mapPersonalInfo),
    phoneNumber: method === 'sms' ? _getOr('', ['textInput'], sendLinkEkycDetail) : '',
    email: method === 'email' ? _getOr('', ['textInput'], sendLinkEkycDetail) : '',
    title: titleEkyc[title],
    frontIdCardConfig: {
      required: true,
      attempts: 3,
      livenessCount: 1,
      threshHold: 0.8,
      dependenciesRequired: false,
      isEditable: true,
    },
    backIdCardConfig: {
      required: true,
      attempts: 3,
      livenessCount: 1,
      threshHold: 0.95,
      dependenciesRequired: false,
      isEditable: true,
    },
    dopaConfig: {
      required: true,
      attempts: 3,
      livenessCount: 1,
      threshHold: 1.0,
      dependenciesRequired: false,
      isEditable: false,
    },
    expiryDuration: 30,
    expiresAt: moment()
      .add(30, 'days')
      .format(),
    notifyDuration: 3,
    notifyInterval: 3,
  }

  const caseKeeperObject = {
    caseType: {
      key: 'default',
      code: '000',
      translations: {
        en: {
          label: 'Default',
        },
        th: {
          label: 'Default',
        },
        id: {
          label: 'Default',
        },
      },
    },
    proprietors: [proprietorsObject],
    remark: '',
    attachMeetingRoom: false,
    appNumber: '',
    policies: [
      {
        policyNumber: '',
      },
    ],
    department: '/new-business',
  }
  return caseKeeperObject
}

export const resendCasePayload = (app, currentProprietorId) => {
  const sendLinkEkycDetail = _getOr({}, ['ekycInfo', 'sendLink'], app)
  const method = _getOr('', ['method'], sendLinkEkycDetail)
  const data = _getOr('', ['textInput'], sendLinkEkycDetail)

  return {
    proprietorId: currentProprietorId,
    data: {
      method,
      email: method === 'email' ? data : '',
      sms: method === 'sms' ? data : '',
    },
  }
}

export const setAttachmentPayload = (insuredOrPayer, app, url, name, type = 'image/jpeg') => {
  const attachmentId =
    name === 'id-card'
      ? insuredOrPayer === 'insured'
        ? `insured-${name}`
        : `owner-${name}`
      : `${insuredOrPayer}-${name}`
  const applicationId = _getOr('', 'applicationId', app)

  return {
    attachmentId,
    applicationId,
    url,
    type,
  }
}
