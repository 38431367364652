import { currentApp } from './primitive-selector'
import { getToggles } from 'quick-quote/feature-toggles'

export const pdpaConsents = (state) => {
  if (getToggles().ENABLE_PDPA_CONSENT) {
    return {
      insurancePrivacyConsent: currentApp('insurancePrivacyConsent.value')(state),
      marketingPrivacyConsent: currentApp('marketingPrivacyConsent.value')(state),
    }
  }
  return null
}
