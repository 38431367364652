import {
  addError,
  addFile,
  addFileProps,
  changeCurrentApp,
  clearLoadingMessage,
  hideDialog,
  hideLoading,
  mergeApplication,
  remoteSellingError,
  remoteSellingSuccess,
  removeError,
  setAppStatusSigned,
  setAppValue,
  setInsuredCommandStatusCompleted,
  setInsuredCommandStatusUploading,
  setLoadingMessage,
  setPayerCommandStatusCompleted,
  setPayerCommandStatusUploading,
  showDialog,
  showLoading,
  showSignPad,
  showSubmitPaymentDialog,
  startSubmitPage,
  uploadRemoteSellingReceipt,
} from 'e-submission/apps/actions'
import { getCurrentApp, getFiles, getFirstName, getLastName, isShowDialog } from 'e-submission/apps/selectors'
import { APP_STATUS } from 'e-submission/domain/data-model/constants'
import {
  getPolicyId,
  getRemoteSellingState,
  getUploadedDocuments,
  isProprietorNeedEkyc,
  isSelectedRemoteSelling,
} from 'e-submission/domain/data-model/form/selectors'
import df2f, { DF2F } from 'e-submission/utils/bridging/df2f'
import { getIdentityUser } from 'identity/selectors'
import _ from 'lodash'
import { isEmpty } from 'lodash'
import { getOr, get } from 'lodash/fp'
import generateUUID from 'uuid/v1'

import { omit, map, flow, keyBy, isNil } from 'lodash/fp'
import ROUTE_PATH from 'quick-quote/constants/path'
import { getRemoteSellingCaseId } from 'quick-quote/remote-selling'
import { isLoading } from 'quick-quote/ui/selectors'
import { call, take, put, select, all, takeLatest, race } from 'redux-saga/effects'
import { onCreateAdditionalDocument, onListDocument, uploadAttachment } from './document'
import { waitTillSuccessOrFailed } from './policy'
import { delay } from 'redux-saga'

export const onResumeAppmanRemoteSelling = function*(history, service, action) {
  console.info('onResumeAppmanRemoteSelling')
  const { listApplicationSummary } = service
  const { appId, caseId } = action.payload
  const user = yield select(getIdentityUser)
  try {
    yield put.resolve(showLoading())
    if (!isNil(appId) && !isEmpty(appId)) {
      const response = yield call(listApplicationSummary, user)
      const appSummaries = flow(map(omit(['_rev'])), keyBy('_id'))(response)
      yield put(mergeApplication(appId, appSummaries))
      const app = appSummaries[appId]
      if (app) {
        yield [put(changeCurrentApp(appId)), take('SAVE_APPLICATION_SUCCESS')]
        const loadedApp = yield select(getCurrentApp)
        const { status } = loadedApp
        console.info('loaded app to resume' + (status || 'DRAFTED'))
        if (status === APP_STATUS.SIGNED || status === APP_STATUS.SUBMITTED) {
          yield call(history.push, ROUTE_PATH.REMOTE_SELLING.SUBMIT)
        } else if (isNil(status) || status === APP_STATUS.DRAFTED) {
          const insuredDocs = get('signedDocs.insured', loadedApp) || []
          const acknowledgeDocs = get('signedDocs.acknowledge', loadedApp) || []
          if (insuredDocs.length > 0 && acknowledgeDocs.length > 0) {
            yield call(history.push, ROUTE_PATH.REMOTE_SELLING.VIDEO_CONSENT)
          } else {
            yield call(history.push, ROUTE_PATH.ESUBMISSION_FORM)
          }
        } else {
          yield call(history.push, ROUTE_PATH.NEED_AND_GAP_ANALYSIS)
        }
        if (caseId) {
          yield put(setAppValue('remoteSelling.caseId', caseId))
        }
        yield put(setAppValue('remoteSelling.loadingVideoConsent', false))
        yield put(remoteSellingSuccess())
      } else {
        console.info('App not found', appId)
        yield put(remoteSellingError({ msg: 'App not found', errorCode: 404 }))
      }
    } else {
      console.info('AppId is empty', appId)
      yield put(remoteSellingError({ msg: 'Not resumed' }))
    }
  } catch (err) {
    console.error('Failed to resume ', err)
    yield put(remoteSellingError())
  } finally {
    yield put(hideLoading())
  }
}
export function* createRemoteSellingApp(action) {
  const { appId } = action
  yield take('LOAD_APP')
  const app = yield select(getCurrentApp)
  const insuredInfo = {
    firstNameInsured: yield select(getFirstName),
    lastNameInsured: yield select(getLastName),
  }
  const isRemoteSelling = isSelectedRemoteSelling(app)
  if (isRemoteSelling) {
    try {
      yield call(df2f.sendEvent, DF2F.CREATE_NEW_CASE, { appId, ...insuredInfo })
    } catch (e) {
      console.error('Tried to create new case in DF2F, but failed')
    }
  }
}

export const onSignedAgentDocuments = function*(history, service) {
  const { patchPolicy } = service
  const app = yield select(getCurrentApp)
  try {
    yield put.resolve(showLoading())
    const documents = yield call(onListDocument, service)
    const isNoSignature = documents.every(({ signature }) => _.isEmpty(signature))
    if (isNoSignature) {
      yield put(hideLoading())
      throw new Error('Signature not signed for', isNoSignature)
    } else {
      const user = yield select(getIdentityUser)
      const policyId = getPolicyId(app)
      const reqPayload = {
        status: APP_STATUS.SIGNED,
      }
      const policy = yield call(patchPolicy, user, policyId, reqPayload)
      if (!policy) {
        throw new Error('Could not sign policy')
      } else {
        const setAppSuccess = yield call(waitTillSuccessOrFailed, setAppStatusSigned)
        if (!setAppSuccess) {
          yield put(showDialog({ name: 'Error', type: 'failed' }))
          return
        } else {
          yield put(startSubmitPage())
          yield call(history.push, ROUTE_PATH.REMOTE_SELLING.SUBMIT)
        }
      }
    }
  } catch (err) {
    yield put(showDialog({ name: 'Error', type: 'failed' }))
  } finally {
    yield put(hideLoading())
  }
}

const uploadAdditionalDocument = function*(service, document, actionName, existingIds = []) {
  try {
    const { name, fileType, url } = document
    yield put(
      addFileProps(actionName, {
        description: name,
      })
    )
    const imageName = yield call(onCreateAdditionalDocument, service, actionName, true)
    const attachment = {
      type: fileType,
      url,
      name: imageName,
      isInsured: true,
      fetch: true,
    }
    yield call(uploadDocumentFromUrl, service, { payload: attachment })
    const files = yield select(getFiles)
    const { description, documentId } = getOr({}, imageName, files)
    yield put(
      addFile(imageName, true, {
        documentId,
        description: description ? description : name,
        creationdate: Date.now(),
        isInsured: true,
        fileType,
      })
    )
    yield put(addFileProps(actionName, { description: '' }))
  } catch (e) {
    console.error('Failed to upload additional document', e)
  }
}

export const sendUploadAdditionalDocument = function*(services, action) {
  try {
    const { name, isInsured, limit } = action.payload
    yield put(isInsured ? setInsuredCommandStatusUploading(name) : setPayerCommandStatusUploading(name))
    const app = yield select(getCurrentApp)
    const existingIds = yield call(getUploadedDocuments, name, app)
    const { files, cancel } = yield race({
      files: call(df2f.sendEvent, DF2F.UPLOAD_EVENT, { limit: limit || 1, type: name, documentIds: existingIds || [] }),
      cancel: take('CANCEL_EVENT'),
    })
    if (files && files.length > 0) {
      const file = files[0]
      const attachment = {
        type: 'image/png',
        url: file.url,
        name,
        isInsured: true,
        fetch: true,
      }
      yield call(uploadDocumentFromUrl, services, { payload: attachment })
    }
    if (cancel) {
      console.info('cancel upload additional document')
    }
    yield put(isInsured ? setInsuredCommandStatusCompleted(name) : setPayerCommandStatusCompleted(name))
  } catch (e) {
    console.error('Failed to upload additional document', e)
  }
}
export const onUploadMultipleDocuments = function*(service, action) {
  const { name: actionName, limit } = action
  try {
    yield put(setAppValue('remoteSelling.isUploading', true))
    const app = yield select(getCurrentApp)
    const existingIds = yield call(getUploadedDocuments, actionName, app) || []
    const files = yield call(df2f.sendEvent, DF2F.UPLOAD_EVENT, {
      limit,
      type: actionName,
      documentIds: existingIds || [],
    })
    yield all(files.map((file) => call(uploadAdditionalDocument, service, file, actionName, existingIds)))
    const fileIds = files.map((file) => file.id)
    yield [
      put(
        setAppValue(`remoteSelling.${actionName}`, {
          documentIds: existingIds.concat(fileIds),
        })
      ),
      take('SAVE_APPLICATION_SUCCESS'),
    ]
    yield [put({ type: 'FETCH_CURRENT_APP' }), take('LOAD_APP')]
  } catch (err) {
    console.error('Failed to upload document', err)
  } finally {
    yield put(setAppValue('remoteSelling.isUploading', false))
  }
}
export const submitCase = function*(service) {
  const user = yield select(getIdentityUser)
  const { submitEkycCase } = service
  const app = yield select(getCurrentApp)
  const isAppmanRemoteSelling = yield call(isSelectedRemoteSelling, app)
  const { succeed, failed } = yield race({
    succeed: take('SUBMIT_POLICY_SUCCEED'),
    failed: take('SUBMIT_POLICY_FAILED'),
  })
  if (isAppmanRemoteSelling) {
    try {
      if (succeed) {
        const caseId = yield call(getRemoteSellingCaseId, app)
        yield call(submitEkycCase, user, caseId)
        yield put({ type: 'HANGUP' })
      }
      if (failed) {
        yield put({ type: 'HANGUP' })
      }
    } catch (e) {
      yield put({ type: 'HANGUP' })
      console.error(e)
    }
  }
}
export function* uploadDocumentFromUrl(services, action) {
  const { saveAttachmentFromURL, getAttachment } = services
  const { url, name, isInsured, fetch, type } = action.payload
  try {
    yield put(removeError(name))
    const user = yield select(getIdentityUser)
    const app = yield select(getCurrentApp)
    const applicationId = get('applicationId', app)
    const document = {
      applicationId,
      url,
      attachmentId: name,
      type,
    }
    yield call(saveAttachmentFromURL, user, document)
    const response = yield call(getAttachment, app, name, user)
    yield [call(uploadAttachment, services, { file: response, name, isInsured }), take('UPLOAD_ATTACHMENT_SUCCESS')]
    if (fetch) {
      yield [put({ type: 'FETCH_CURRENT_APP' }), take('LOAD_APP')]
    }
  } catch (err) {
    yield put(addError(name, err))
    console.error(err)
  } finally {
    yield put(isInsured ? setInsuredCommandStatusCompleted(name) : setPayerCommandStatusCompleted(name))
  }
}

export const onSentQrPaymentEvent = function*(history, action) {
  const { documentTypeName, data, bankList, documentIds } = action.payload
  const payloadData = {
    type: 'qr',
    data,
    uploadConfig: {
      type: documentTypeName,
      limit: 1,
      locale: 'th',
    },
    bankList,
    documentIds,
  }
  try {
    yield put(showSubmitPaymentDialog())
    yield put(setLoadingMessage('ลูกค้ากำลังชำระเงิน กรุณารอสักครู่'))
    yield delay(1000)
    const { response, cancel } = yield race({
      response: call(df2f.sendEvent, DF2F.PAYMENT_EVENT, payloadData),
      cancel: take('CANCEL_EVENT'),
    })
    console.info('QR PAYMENT EVENT RESPONSE', response)
    if (response) {
      const { success } = response
      if (success) {
        yield put(uploadRemoteSellingReceipt({ data: response, name: documentTypeName }))
      }
    }
    if (cancel) {
      yield put(hideDialog())
      history.push(ROUTE_PATH.REMOTE_SELLING.REMOTE_PAYMENT)
      return
    }
  } catch (e) {
    yield put(hideDialog())
    history.push(ROUTE_PATH.REMOTE_SELLING.REMOTE_PAYMENT)
    console.error('Failed to send QR payment event', e)
  } finally {
    yield put(hideDialog())
    yield put(clearLoadingMessage())
  }
}

export const updateClientStatus = function*(action) {
  const { isClientStreaming, isInCall } = action.payload

  yield put(setAppValue('remoteSelling.isInCall', isInCall))
  yield put(setAppValue('remoteSelling.callStatus', action.payload))

  if (isClientStreaming === false) {
    yield call(cancelEvent)
  }
}
export const sendPreviewDocument = function*(service, action) {
  try {
    yield put.resolve(showLoading())
    const { getBase64Document } = service
    const user = yield select(getIdentityUser)
    const document = action.payload
    const response = yield call(getBase64Document, user, document)
    const docData = {
      code: response.code,
      name: document.shortText || document.text,
      data: response.data,
    }
    const payload = {
      needSignature: document?.needSignature || false,
      currentDocument: 1,
      maximumDocuments: 1,
      messageID: generateUUID(),
      documents: docData,
    }
    yield put(setLoadingMessage('ลูกค้ากำลังลงนามเอกสาร กรุณารอสักครู่'))
    const { signature, cancel } = yield race({
      signature: call(df2f.sendEvent, DF2F.REVIEW_DOCUMENT, payload),
      cancel: take('CANCEL_EVENT'),
    })
    if (cancel) {
      return
    }
    if (signature) {
      yield put(setAppValue('clientSignature', signature['url']))
      yield put(hideLoading())
      yield put(showSignPad(document))
    }
  } catch (e) {
    console.error(e)
    yield put(hideLoading())
  } finally {
    yield put(clearLoadingMessage())
  }
}
export const cancelEvent = function*(action) {
  const { name, isInsured } = action?.payload || {}
  const app = yield select(getCurrentApp)
  const isUploading = get('remoteSelling.isUploading', app)
  const isUiLoading = yield select(isLoading)
  const isDialogOpen = yield select(isShowDialog)
  if (name) {
    yield put(isInsured ? setInsuredCommandStatusCompleted(name) : setPayerCommandStatusCompleted(name))
  }
  if (isUploading) yield put(setAppValue('remoteSelling.isUploading', false))

  if (isUiLoading) yield put(hideLoading())

  if (isDialogOpen) yield put(hideDialog())

  yield call(df2f.sendEvent, DF2F.CANCEL_EVENT)
}

export const onConfirmSubmittedPolicy = function*(service) {
  try {
    const { retrieveEkycReport } = service
    const user = yield select(getIdentityUser)
    const app = yield select(getCurrentApp)
    const applicationId = get('_id', app)
    const remoteSellingState = getRemoteSellingState(app)
    const insuredProprietorId = isProprietorNeedEkyc(app, 'insured') && get('insured.proprietorId', remoteSellingState)
    const payerProprietorId = isProprietorNeedEkyc(app, 'payer') && get('payer.proprietorId', remoteSellingState)
    let requestPayload = {
      applicationId,
    }
    yield put(showLoading())
    if (insuredProprietorId) {
      yield call(retrieveEkycReport, user, {
        ...requestPayload,
        attachmentId: 'insured-ekyc',
        proprietorId: insuredProprietorId,
      })
    }
    if (payerProprietorId) {
      yield call(retrieveEkycReport, user, {
        ...requestPayload,
        proprietorId: payerProprietorId,
        attachmentId: 'payer-ekyc',
      })
    }
    yield [put({ type: 'FETCH_CURRENT_APP' }), take('LOAD_APP')]
    yield put(hideLoading())
    yield put(startSubmitPage())
  } catch {
    yield put(hideLoading())
    yield put(startSubmitPage())
  }
}

export default function*(service, history) {
  yield all([
    takeLatest('UPLOAD_MULTIPLE_DOCUMENTS', onUploadMultipleDocuments, service),
    takeLatest('RESUME_APPMAN_REMOTE_SELLING', onResumeAppmanRemoteSelling, history, service),
    takeLatest('SIGNED_AGENT_DOCUMENTS', onSignedAgentDocuments, history, service),
    takeLatest('SUBMIT_POLICY', submitCase, service),
    takeLatest('ESUB_UPLOAD_DOCUMENT_FROM_URL', sendUploadAdditionalDocument, service),
    takeLatest('SEND_QR_PAYMENT_EVENT', onSentQrPaymentEvent, history),
    takeLatest('UPDATE_CALL_STATUS_REMOTE', updateClientStatus),
    takeLatest('SEND_PREVIEW_DOCUMENT', sendPreviewDocument, service),
    takeLatest('CANCEL_EVENT', cancelEvent),
    takeLatest('CREATE_NEW_APP', createRemoteSellingApp),
    takeLatest('SUBMITTED_POLICY', onConfirmSubmittedPolicy, service),
    takeLatest('BACK_TO_HOME', function*() {
      yield put('RESET_APP_STATE')
    }),
  ])
}
