import { currentApp } from './primitive-selector'
import { getToggles } from 'quick-quote/feature-toggles'

export const fna = (state) => {
  if (getToggles().ENABLE_FNA_FLOW) {
    return {
      FNA: currentApp('FNA')(state),
    }
  }
  return null
}
