import _ from 'lodash'
import {
  isEqual,
  isEmpty,
  reject,
  filter,
  has,
  map,
  flatMap,
  flow,
  find,
  isString,
  isNil,
  getOr,
  omit,
  get,
  compact,
} from 'lodash/fp'
import { select, call, all, put, take, takeLatest, takeEvery, race } from 'redux-saga/effects'
import { delay } from 'redux-saga'

import {
  isAcknowledge,
  getReceipts,
  isReceiptSigned,
  isAdditionalDocument,
  isCreditCardRelationshipDocument,
  getAppStatus,
  getReceiptKey,
  getReceiptFromDocument,
  getReceiptNo,
  isReceipt,
  getPolicyId,
  listSignature,
  getPaidReceipts,
  isSelectedRemoteSelling,
  hasReceiptPaid,
  isDaRelationshipDocument,
} from 'e-submission/domain/data-model/form/selectors'
import { getFiles, getCurrentApp, getCurrentValue } from 'e-submission/apps/selectors'
import {
  previewPhoto,
  removeFile,
  signDocument,
  showErrorDialog,
  showSignatureDialog,
  showPreviewPDFDialog,
  showPreviewMultiplePDFDialog,
  removeError,
  addError,
  setAppValue,
  addFile,
  addFileProps,
  showLoading,
  hideLoading,
  hideDialog,
  showSignPad,
  setLoadingMessage,
  clearLoadingMessage,
  saveAppData,
} from 'e-submission/apps/actions'
import { isCordova, base64ToBlob } from 'e-submission/utils'
import createSignPad from 'e-submission/utils/sign-pad'
import { APP_STATUS, DOCUMENT_TYPE } from 'e-submission/domain/data-model/constants'

import { getIdentityUser } from 'identity/selectors'

import { getToggles } from 'quick-quote/feature-toggles'
import { updateOptyStagePayment } from 'quick-quote/opty/actions'
import { getAppConfig } from 'deploy-env/app-config'
import applicationCordovaWrapper from 'e-submission/domain/data/application-cordova-wrapper'

import MobileDetect from 'mobile-detect'
import generateUUID from 'uuid/v1'
import df2f, { DF2F } from 'e-submission/utils/bridging/df2f'
import { AVAILABLE_IMAGE_FORMATS } from 'e-submission/utils/pattern'

const mapWithKey = map.convert({ cap: false })

export const uploadAttachment = function*(service, attachment) {
  const { setAttachment, getApplicationRev } = service
  const { name, file, isInsured } = attachment

  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)

  // save app data before upload in order to get the correct application value
  if (getToggles().ENABLE_EDA) {
    yield put(saveAppData())
    yield take('SAVE_APPLICATION_SUCCESS')
  }

  while (true) {
    try {
      const _rev = yield call(getApplicationRev, app, user)
      const newApp = {
        ...app,
        _rev,
      }
      yield call(setAttachment, newApp, name, file, user, isInsured)
      yield put({ type: 'UPLOAD_ATTACHMENT_SUCCESS' })

      break
    } catch (err) {
      if (get('response.status', err) !== 409) {
        throw err
      }
    }
  }
}

export const onListDocument = function*(service, action) {
  const { listDocument } = service
  const isFetch = !isNil(action)
  const app = yield select(getCurrentApp)
  let policyId = getPolicyId(app)
  if (!policyId) return []
  let documentList = []

  try {
    if (isFetch) {
      yield put.resolve(showLoading())
    }

    const user = yield select(getIdentityUser)
    const signatures = listSignature(app)
    yield map(({ key }) => put(removeFile(key)), signatures)
    const insuredDocs = get('signedDocs.insured', app) || []
    const acknowledgeDocs = get('signedDocs.acknowledge', app) || []
    const isAppmanRemoteSelling = isSelectedRemoteSelling(app)
    const shouldAddEcbr = isAppmanRemoteSelling && insuredDocs.length > 0 && acknowledgeDocs.length > 0
    if (getAppStatus(app) === APP_STATUS.SIGNED || shouldAddEcbr) {
      const getECBRKey = (receiptNo) =>
        flow(getReceiptKey(receiptNo), (key) => {
          return `ECBR_${key}`
        })(app)

      yield flow(
        getReceipts,
        filter(isReceiptSigned),
        map(({ receiptNo }) => put(addFile(getECBRKey(receiptNo), true)))
      )(app)
    }

    const documents = yield call(listDocument, user, policyId)
    const getKey = (path) => flow(find(path), get('key'))(signatures)

    yield flow(
      reject(flow(get('signature'), isEmpty)),
      flatMap(({ code, signature }) =>
        mapWithKey((value, signedBy) => put(addFile(getKey({ signedBy, documentName: code }), true)))(signature)
      )
    )(documents)
    const addionalAndCreditCardRelationshipDocuments = documents.filter(
      ({ documentType }) => isAdditionalDocument(documentType) || isCreditCardRelationshipDocument(documentType)
    )
    yield flow(
      map(({ documentType, documentId, description, creationdate, isInsured }) =>
        put(
          addFileProps(`${documentType}_${documentId}`, {
            documentId,
            description,
            creationdate: new Date(creationdate).valueOf(),
            isInsured,
          })
        )
      )
    )(addionalAndCreditCardRelationshipDocuments)

    documentList = documents
  } catch (err) {
    console.error(err)
  } finally {
    if (isFetch) {
      yield put(hideLoading())
    }
  }

  return documentList
}

/* istanbul ignore next */
const isMobile = {
  Android: () => {
    return navigator.userAgent.match(/Android/i)
  },
  BlackBerry: () => {
    return navigator.userAgent.match(/BlackBerry/i)
  },
  iOS: () => {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
  },
  Opera: () => {
    return navigator.userAgent.match(/Opera Mini/i)
  },
  Windows: () => {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i)
  },
  any: () => {
    return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows()
  },
}

export const onShowSignPad = function*(service, action) {
  const document = action.payload

  const app = yield select(getCurrentApp)
  const person = document.sectionId

  const mobileDetect = new MobileDetect(window.navigator.userAgent)

  if (isAcknowledge(person)) {
    // Mock sigature with one pixel image
    yield call(onSignEmptySignatureDocument, service, document)
    if (getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM) {
      const docsName = document.allDocuments.map((d) => d.name)
      yield [put(setAppValue('signedDocs.acknowledge', docsName)), take('SAVE_APPLICATION_SUCCESS')]
    }
    return
  }

  if (isCordova || getToggles().ENABLE_MOUSE_SIGNING || mobileDetect.mobile()) {
    yield put(showSignatureDialog(document))
  } else {
    console.info('Not on Mobile Device, WAITING FOR SIGNPAD...')
    yield put.resolve(showLoading())
    const { waitSign, destroy } = createSignPad()

    try {
      const params = {
        firstName: get([person, 'firstName'], app),
        lastName: get([person, 'lastName'], app),
      }

      const { base64 } = yield race({
        base64: call(waitSign, params),
        cancelled: take('HIDE_DIALOG'),
      })

      if (base64) {
        yield put(
          signDocument({
            ...document,
            data: base64,
          })
        )
      }
    } catch (err) {
      console.error(err)
    } finally {
      destroy()
      yield put(hideLoading())
      yield put(hideDialog())
    }
  }
}

export const onSignDocument = function*(service, action) {
  const { signDocument, signAllDocument, signPayment } = service
  const user = yield select(getIdentityUser)

  try {
    yield put.resolve(showLoading())

    let document = action.payload
    const app = yield select(getCurrentApp)
    const isAppmanRemoteSelling = isSelectedRemoteSelling(app)

    if (isReceipt(document) && !isAppmanRemoteSelling) {
      const receipt = getReceiptFromDocument(app, document)
      const receiptNo = getReceiptNo(receipt)
      const payment = {
        ...document,
        receiptNo,
      }

      yield call(signPayment, user, payment)

      const receipts = getReceipts(app)

      const receiptsWithSign = receipts.map((val) =>
        isEqual(receiptNo)(getReceiptNo(val)) ? { ...val, signed: true } : val
      )
      yield put(setAppValue('receipts', receiptsWithSign))

      if (receiptsWithSign && receiptsWithSign.length > 0) {
        yield put(updateOptyStagePayment())
      }
    } else if (document.allDocuments && document.allDocuments.length > 0) {
      // check if any document in document.allDocuments is a receipt
      const receipts = document.allDocuments.filter(isReceipt)
      if (receipts.length) {
        const receipt = getReceiptFromDocument(app, document)
        const receiptNo = getReceiptNo(receipt)
        const payment = {
          ...document,
          receiptNo,
        }

        yield call(signPayment, user, payment)

        const receipts = getReceipts(app)

        const receiptsWithSign = receipts.map((val) =>
          isEqual(receiptNo)(getReceiptNo(val)) ? { ...val, signed: true } : val
        )
        yield put(setAppValue('receipts', receiptsWithSign))
      }
      const docsName = _.compact(
        document.allDocuments.map((d) => {
          if (!isReceipt(d)) return d.name
        })
      )
      // if paid and remoteSelling document.signature.signedBy = advisor
      const isPaid = hasReceiptPaid(app)
      yield call(
        signAllDocument,
        user,
        {
          ...document,
          signature: {
            ...document.signature,
            signedBy: isPaid && isAppmanRemoteSelling ? 'advisor' : document.signature.signedBy,
          },
        },
        docsName
      )
      if (getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM || isAppmanRemoteSelling) {
        yield [
          put(
            setAppValue(
              `signedDocs.${isPaid && isAppmanRemoteSelling ? 'advisor' : document.signature.signedBy}`,
              docsName
            )
          ),
          take('SAVE_APPLICATION_SUCCESS'),
        ]
      }
    } else {
      yield call(signDocument, user, document)
    }

    yield call(onListDocument, service, { payload: { isFetch: true } })
  } catch (err) {
    console.error(err)
  } finally {
    yield [put(setAppValue('clientSignature', null)), take('SAVE_APPLICATION_SUCCESS')]
    yield put(hideLoading())
  }
}

export const onSignEmptySignatureDocument = function*(service, document) {
  const { signEmptySignatureDocument } = service
  const user = yield select(getIdentityUser)

  try {
    yield put.resolve(showLoading())
    yield put(hideDialog())
    yield call(signEmptySignatureDocument, user, document)
    yield call(onListDocument, service, { payload: { isFetch: true } })
  } catch (err) {
    console.error(err)
  } finally {
    yield put(hideLoading())
  }
}

export const onPreviewPDF = function*(service, action) {
  const { getPayment, getDocument, previewDocument, previewPayment } = service

  const user = yield select(getIdentityUser)

  const document = action.payload

  yield put.resolve(showLoading())

  if (isCordova) {
    try {
      let status

      if (isReceipt(document)) {
        const app = yield select(getCurrentApp)
        const receipt = getReceiptFromDocument(app, document)
        const receiptNo = getReceiptNo(receipt)
        const payment = {
          ...document,
          receiptNo,
        }
        status = yield call(previewPayment, user, payment)
      } else {
        status = yield call(previewDocument, user, document)
      }

      if (status === 'sign' && !document.readOnly) {
        if (isAcknowledge(document.sectionId)) {
          yield call(onSignEmptySignatureDocument, service, document)
          if (getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM) {
            const docsName = document.allDocuments.map((d) => d.name)
            yield [put(setAppValue('signedDocs.acknowledge', docsName)), take('SAVE_APPLICATION_SUCCESS')]
          }
        } else {
          yield put(showSignatureDialog(document))
        }
      }
    } catch (err) {
      console.error(err)
      yield put(showErrorDialog('failed'))
    } finally {
      yield put(hideLoading())
    }
  } else {
    try {
      if (isReceipt(document)) {
        const app = yield select(getCurrentApp)
        const receipt = getReceiptFromDocument(app, document)
        const receiptNo = getReceiptNo(receipt)
        const payment = {
          ...document,
          receiptNo,
        }
        const response = yield call(getPayment, user, payment)
        yield put(addFile(document.name, response))
      } else {
        const response = yield call(getDocument, user, document)
        yield put(addFile(document.name, response))
      }

      yield put(showPreviewPDFDialog(document))
    } catch (err) {
      console.error(err)
    } finally {
      yield put(hideLoading())
    }
  }
}

export const onLoadDocument = function*(service, action) {
  const { getDocument, getPayment } = service
  let response
  const user = yield select(getIdentityUser)
  const document = action.payload
  if (isReceipt(document)) {
    const app = yield select(getCurrentApp)
    const receipt = getReceiptFromDocument(app, document)
    const receiptNo = getReceiptNo(receipt)
    const payment = {
      ...document,
      receiptNo,
    }
    response = yield call(getPayment, user, payment)
  } else {
    response = yield call(getDocument, user, document)
  }
  yield put(addFile(document.name, response))
}

const getSignatureDocument = function*(service, document, index, docLength) {
  const user = yield select(getIdentityUser)
  let uuid = generateUUID()
  const { getBase64Document, getBase64Payment } = service
  let response
  if (isReceipt(document)) {
    const app = yield select(getCurrentApp)
    const paidReceipts = getPaidReceipts(app)
    const receipt = paidReceipts.length > 0 ? paidReceipts[0] : undefined
    const receiptNo = getReceiptNo(receipt)
    const payment = {
      ...document,
      receiptNo,
    }
    response = yield call(getBase64Payment, user, payment)
  } else {
    response = yield call(getBase64Document, user, document)
  }

  const docData = {
    code: response.code,
    name: document.shortText || document.text,
    data: response.data,
  }
  const payloadData = {
    needSignature: true,
    currentDocument: index + 1,
    maximumDocuments: docLength,
    messageID: uuid,
    documents: docData,
  }
  return payloadData
}

export const onGetMultipleDocument = function*(service, action) {
  yield put.resolve(showLoading())
  try {
    const { documents, documentData } = action.payload
    const docLength = documents.length
    const payload = yield all(
      documents.map((document, index) => getSignatureDocument(service, document, index, docLength))
    )
    const sendData = (payloadData) => {
      return new Promise((resolve) => {
        df2f.sendEvent(
          DF2F.REVIEW_DOCUMENT,
          payloadData,
          payloadData.currentDocument === payloadData.maximumDocuments
            ? (e) => {
                console.info('DF2F.REVIEW_DOCUMENT', e)
                if (e && e.url) {
                  resolve(e.url)
                }
              }
            : resolve()
        )
      })
    }
    yield put(setLoadingMessage('ลูกค้ากำลังลงนามเอกสาร กรุณารอสักครู่'))
    const { signature, cancel } = yield race({
      signature: all(payload.map((payloadData) => call(sendData, payloadData))),
      cancel: take('CANCEL_EVENT'),
    })
    if (signature) {
      yield put(showSignPad(documentData))
      yield [put(setAppValue('clientSignature', compact(signature)[0])), take('SAVE_APPLICATION_SUCCESS')]
    }
    if (cancel) {
      return
    }
  } catch (err) {
    console.error(err)
  } finally {
    yield put(hideLoading())
    yield put(clearLoadingMessage())
  }
}

export const onPreviewMultiplePDF = function*(service, action) {
  const { getDocument, previewDocument, previewPayment, getPayment } = service

  const user = yield select(getIdentityUser)

  const app = yield select(getCurrentApp)
  let policyId = getPolicyId(app)
  if (!policyId) return
  const document = action.payload

  yield put.resolve(showLoading())

  if (isCordova && !getToggles().ENABLE_ONLY_WEBVIEW_FOR_DOCUMENT_PDF) {
    try {
      let status
      if (isReceipt(document)) {
        const app = yield select(getCurrentApp)
        const receipt = getReceiptFromDocument(app, document)
        const receiptNo = getReceiptNo(receipt)
        const payment = {
          ...document,
          receiptNo,
        }
        status = yield call(previewPayment, user, payment)
      } else {
        status = yield call(previewDocument, user, document)
      }

      if (status === 'sign' && !document.readOnly) {
        if (isAcknowledge(document.sectionId)) {
          yield call(onSignEmptySignatureDocument, service, document)
          if (getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM) {
            const docsName = document.allDocuments.map((d) => d.name)
            yield [put(setAppValue('signedDocs.acknowledge', docsName)), take('SAVE_APPLICATION_SUCCESS')]
          }
        } else {
          yield put(showSignatureDialog(document))
        }
      }
    } catch (err) {
      console.error(err)
      yield put(showErrorDialog('failed'))
    } finally {
      yield put(hideLoading())
    }
  } else {
    try {
      let response
      if (isReceipt(document)) {
        const app = yield select(getCurrentApp)
        const receipt = getReceiptFromDocument(app, document)
        const receiptNo = getReceiptNo(receipt)
        const payment = {
          ...document,
          receiptNo,
        }
        response = yield call(getPayment, user, payment)
      } else {
        response = yield call(getDocument, user, document)
      }
      yield put(addFile(document.name, response))
      yield put(showPreviewMultiplePDFDialog(document))
    } catch (err) {
      console.error(err)
    } finally {
      yield put(hideLoading())
    }
  }
}

export const onCapturePhoto = function*(service, action) {
  const { deleteAdditionalDocument } = service

  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  let imageName = action.name

  if (isNil(imageName)) {
    return
  }

  try {
    yield put.resolve(showLoading())

    if (isAdditionalDocument(imageName) || isCreditCardRelationshipDocument(imageName)) {
      imageName = yield call(onCreateAdditionalDocument, service, imageName)
    }

    const base64 = yield call(applicationCordovaWrapper.openCamera, app, imageName)
    const base64Image = getOr('', 'image', base64)
    const blob = yield call(base64ToBlob, base64Image, 'image/png')

    let File = window.File
    if ('File_native' in window) {
      File = window['File_native']
    }

    const file = blob ? new File([blob], imageName, { type: 'image/png' }) : {}

    const attachment = {
      file,
      name: imageName,
    }

    yield call(uploadAttachment, service, attachment)
    yield [put({ type: 'FETCH_CURRENT_APP' }), take('LOAD_APP')]
    if (action.isShowPreview) {
      yield [
        put(previewPhoto(imageName)),
        race({
          success: take('ADD_FILE'),
          fail: take('SHOW_DIALOG'),
        }),
      ]
    }

    if (isAdditionalDocument(imageName) || isCreditCardRelationshipDocument(imageName)) {
      const files = yield select(getFiles)
      const { description, documentId } = getOr({}, action.name, files)

      yield put(
        addFile(imageName, true, {
          documentId,
          description,
          creationdate: Date.now(),
        })
      )
      yield put(
        addFileProps(action.name, {
          description: '',
        })
      )
    }
  } catch (err) {
    console.error(err)
    yield put(addError(action.name, err))
    try {
      if (isAdditionalDocument(imageName) || isCreditCardRelationshipDocument(imageName)) {
        const policyId = getPolicyId(app)
        const files = yield select(getFiles)
        const { documentId } = get(action.name, files)

        yield call(
          deleteAdditionalDocument,
          user,
          {
            documentId,
            policyId,
          },
          imageName
        )
      }
    } catch (err) {}
  } finally {
    yield put(hideLoading())
  }
}

export const onBrowsePhoto = function*(service, action) {
  // Limit photo size
  const limit_in_mb = getAppConfig().MAXIMUM_FILE_SIZE_LIMIT_IN_MB
  const limit = limit_in_mb * 1024 * 1024
  const image = action.payload
  const imageSize = getOr(0, 'size', image)
  let imageName = action.name
  let documentType = action.name
  const format = action.format

  if (!AVAILABLE_IMAGE_FORMATS.includes(format) || !AVAILABLE_IMAGE_FORMATS.includes(image.type)) {
    yield put(addError(action.name, 'INVALID_IMAGE_FILE'))
    return
  }

  if (imageSize > limit) {
    yield put(addError(action.name, 'FILE_SIZE_OVER_LIMIT'))
    return
  }

  if (isNil(documentType) || isNil(image)) {
    return
  }

  try {
    yield put.resolve(showLoading())

    if (isAdditionalDocument(documentType) || isCreditCardRelationshipDocument(documentType)) {
      imageName = yield call(onCreateAdditionalDocument, service, documentType)
    } else if (isDaRelationshipDocument(documentType)) {
      if (getToggles().ENABLE_EDA) {
        imageName = yield call(onCreateDaRelationshipDocument, service, documentType)
      }
    }

    let attachment = {
      file: image,
      name: imageName,
    }
    yield call(uploadAttachment, service, attachment)

    yield [put({ type: 'FETCH_CURRENT_APP' }), take('LOAD_APP')]

    yield put(removeError(action.name))

    if (action.isShowPreview) {
      if (isCordova) {
        yield put(addFile(imageName, image))
      } else {
        yield [
          put(previewPhoto(imageName)),
          race({
            success: take('ADD_FILE'),
            fail: take('SHOW_DIALOG'),
          }),
        ]
      }
    }

    if (isAdditionalDocument(imageName) || isCreditCardRelationshipDocument(imageName)) {
      const files = yield select(getFiles)
      const { description, documentId } = getOr({}, action.name, files)

      yield put(
        addFile(imageName, true, {
          documentId,
          description,
          creationdate: Date.now(),
        })
      )
      yield put(
        addFileProps(action.name, {
          description: '',
        })
      )
    }

    attachment = null
  } catch (err) {
    console.error(err)
    yield put(addError(imageName, err))
  } finally {
    yield put(hideLoading())
  }
}

export const onClonePhoto = function*(service, action) {
  const { getAttachment } = service

  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  const { from, destination } = action
  try {
    yield put.resolve(showLoading())

    let [image] = yield [call(getAttachment, app, from, user), delay(1000)]
    const attachment = {
      file: image,
      name: destination,
    }
    yield call(uploadAttachment, service, attachment)

    yield [put({ type: 'FETCH_CURRENT_APP' }), take('LOAD_APP')]
  } catch (err) {
    console.error(err)
  } finally {
    yield put(hideLoading())
  }
}

export const onPreviewPhoto = function*(service, action) {
  const { getAttachment } = service

  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  const imageName = action.name

  if (isNil(imageName)) {
    return
  }

  try {
    yield put.resolve(showLoading())

    let [image] = yield [call(getAttachment, app, imageName, user), delay(1000)]
    if (isString(image)) {
      image = 'data:image/jpeg;base64,' + image
    }

    yield put(addFile(imageName, image))
  } catch (err) {
    console.error(err)
  } finally {
    yield put(hideLoading())
  }
}

export const onDeletePhoto = function*(service, action) {
  const { deleteAdditionalDocument } = service
  const imageName = action.name

  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  const attachments = yield select(getCurrentValue, '_attachments')
  const newAttachments = omit(imageName, attachments)

  try {
    yield put.resolve(showLoading())
    const documentType = isCreditCardRelationshipDocument(imageName)
      ? DOCUMENT_TYPE['insured']['CREDIT_CARD_RELATIONSHIP_DOCUMENT']['name']
      : DOCUMENT_TYPE['insured']['ADDITIONAL_DOCUMENT']['name']

    if (isAdditionalDocument(imageName) || isCreditCardRelationshipDocument(imageName)) {
      const policyId = getPolicyId(app)
      const files = yield select(getFiles)
      const { documentId } = get(imageName, files)

      yield call(
        deleteAdditionalDocument,
        user,
        {
          documentId,
          policyId,
        },
        documentType
      )

      yield put(removeFile(imageName))
    }

    yield [put(setAppValue('_attachments', newAttachments)), take('SAVE_APPLICATION_SUCCESS')]

    yield [put(setAppValue('isEvidenceUploaded', false))]
  } catch (err) {
    console.error(err)
  } finally {
    yield put(hideLoading())
  }
}

export const onCreateAdditionalDocument = function*(service, documentName, selectedRemoteSelling) {
  const { createAdditionalDocument } = service
  const files = yield select(getFiles)

  if (!has(documentName, files) && !selectedRemoteSelling) {
    return documentName // additional-docs
  }
  const { description } = getOr({}, documentName, files)

  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  const policyId = getPolicyId(app)

  const { documentId } = yield call(
    createAdditionalDocument,
    user,
    {
      policyId,
      description: description || '',
    },
    documentName
  )
  const attachmentName = `${documentName}_${documentId}`
  yield put(
    addFileProps(selectedRemoteSelling ? attachmentName : documentName, {
      documentId,
      isInsured: selectedRemoteSelling,
    })
  )

  return attachmentName
}

export const onCreateDaRelationshipDocument = function*(service, documentName, selectedRemoteSelling) {
  const { createAdditionalDocument } = service

  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  const policyId = getPolicyId(app)

  const { documentId } = yield call(
    createAdditionalDocument,
    user,
    {
      policyId,
      description: 'เอกสารพิสูจน์ความสัมพันธ์',
    },
    documentName
  )

  yield put(
    addFileProps(documentName, {
      documentId,
      isInsured: selectedRemoteSelling,
    })
  )

  return documentName
}

export const onSaveAuraSubmitDataToMiddelware = function*(service, action) {
  const { getDataAuraMiddleware } = service

  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  const { payload } = action
  const { applicationId } = app

  if (isNil(payload)) {
    return
  }

  try {
    yield put.resolve(showLoading())
    const { insured, payer } = payload
    const resultGetDataState = yield call(getDataAuraMiddleware, user, applicationId)

    const prepAuraObj = {
      dataInsured: {
        ...resultGetDataState.insured,
        ...insured,
      },
      dataPayer: {
        ...resultGetDataState.payer,
        ...payer,
      },
    }

    if (!_.isEmpty(prepAuraObj.dataInsured)) {
      yield put(setAppValue('insured', prepAuraObj.dataInsured))
    }

    if (!_.isEmpty(prepAuraObj.dataPayer)) {
      yield put(setAppValue('payer', prepAuraObj.dataPayer))
    }
  } catch (err) {
    console.error(err)
  } finally {
    yield put(hideLoading())
  }
}

export const onSaveEcbrPhoto = function*(service, action) {
  const { getAttachment } = service

  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  const { from, destination } = action

  let uploadSuccess = false
  try {
    yield put.resolve(showLoading())
    let [image] = yield [call(getAttachment, app, from, user), delay(1000)]
    const attachment = {
      file: image,
      name: destination,
    }
    yield [call(uploadAttachment, service, attachment), delay(1000)]
    yield [put({ type: 'FETCH_CURRENT_APP' }), take('LOAD_APP')]

    const attachments = yield select(getCurrentValue, '_attachments')
    const newAttachments = _.omit(attachments, from)
    yield put(removeFile(from))
    yield put(setAppValue('_attachments', newAttachments))
    yield [put({ type: 'FETCH_CURRENT_APP' }), take('LOAD_APP')]
    uploadSuccess = true
  } catch (err) {
    console.error(err)
  } finally {
    yield put(hideLoading())
    if (uploadSuccess) {
      yield put({ type: 'ECBR_PHOTO_SUCCESS' })
    }
  }
}

export default function*(service) {
  yield all([
    takeLatest('FETCH_SIGNATURE', onListDocument, service),
    takeLatest('SHOW_SIGNPAD', onShowSignPad, service),
    takeLatest('SIGN_DOCUMENT', onSignDocument, service),
    takeLatest('PREVIEW_PDF', onPreviewPDF, service),
    takeLatest('PREVIEW_MULTIPLE_PDF', onPreviewMultiplePDF, service),
    takeLatest('CAPTURE_PHOTO', onCapturePhoto, service),
    takeEvery('BROWSE_PHOTO', onBrowsePhoto, service),
    takeLatest('CLONE_PHOTO', onClonePhoto, service),
    takeLatest('PREVIEW_PHOTO', onPreviewPhoto, service),
    takeLatest('DELETE_PHOTO', onDeletePhoto, service),
    takeLatest('LOAD_DOCUMENT', onLoadDocument, service),
    takeEvery('SEND_MULTIPLE_DOCUMENT', onGetMultipleDocument, service),
    takeLatest('UPLOAD_ATTACHMENT', uploadAttachment, service),
    takeLatest('SAVE_DATA_AURA_TO_MIDDLEWARE', onSaveAuraSubmitDataToMiddelware, service),
    takeLatest('SAVE_ECBR_PHOTO', onSaveEcbrPhoto, service),
  ])
}
