'use strict'
import { defaultTo, flow, get, map } from 'lodash/fp'
import { currentApp } from './primitive-selector'

export const families = (state) => flow(currentApp('family'), defaultTo([]), map(family))(state)

const family = (familyItem) => {
  const firstName = get('firstName')(familyItem)
  const lastName = get('lastName')(familyItem)
  const title = get('title.text')(familyItem)
  const idNo = get('idNo')(familyItem)
  const policyNumber = get('policyNumber')(familyItem)
  const relations = get('relations')(familyItem)

  return {
    firstName,
    lastName,
    title,
    relations,
    idNo,
    policyNumber,
  }
}
