import { get } from 'lodash/fp'
import { currentApp, currentQQ } from './primitive-selector'

export const agent = (state) => {
  const agent = currentQQ('agent')(state)
  const fseAdvisorCode = get('advisorCode')(agent)
  const fseAdvisorId = get('fseCode')(agent)
  const fseAdvisorFirstName = get('firstName')(agent)
  const fseAdvisorLastName = get('lastName')(agent)
  const fseCorporateEmail = get('corporateEmail')(agent)
  const fsePersonalEmail = get('personalEmail')(agent)
  const address1 = get('address1')(agent)
  const address2 = get('address2')(agent)
  const branchCode = get('branchCode')(agent)
  const branchName = get('branchName')(agent)
  const regionCode = get('regionCode')(agent)
  const regionName = get('regionName')(agent)
  const areaCode = get('areaCode')(agent)
  const areaName = get('areaName')(agent)
  const managerCode = get('managerCode')(agent)
  const managerFirstName = get('managerFirstName')(agent)
  const managerLastName = get('managerLastName')(agent)
  const recommenderAdvisorCode = currentApp('agent.recommender.advisorCode')(state)
  const recommenderAdvisorId = currentApp('agent.recommender.advisorId')(state)
  const recommenderAdvisorFirstName = currentApp('agent.recommender.firstName')(state)
  const recommenderAdvisorLastName = currentApp('agent.recommender.lastName')(state)
  const recommenderRegistrations = currentApp('agent.recommender.registrations')(state)
  return {
    recommender: {
      advisorCode: recommenderAdvisorCode,
      advisorId: recommenderAdvisorId,
      firstName: recommenderAdvisorFirstName,
      lastName: recommenderAdvisorLastName,
      registrations: recommenderRegistrations,
    },
    fse: {
      advisorCode: fseAdvisorCode,
      advisorId: fseAdvisorId,
      firstName: fseAdvisorFirstName,
      lastName: fseAdvisorLastName,
      corporateEmail: fseCorporateEmail,
      personalEmail: fsePersonalEmail,
    },
    office: {
      address: {
        address1,
        address2,
      },
      manager: {
        code: managerCode,
        firstName: managerFirstName,
        lastName: managerLastName,
      },
      branch: {
        code: branchCode,
        name: branchName,
      },
      area: {
        code: areaCode,
        name: areaName,
      },
      region: {
        code: regionCode,
        name: regionName,
      },
    },
  }
}
