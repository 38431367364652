import _ from 'lodash'
import { currentApp } from './primitive-selector'

const getSelectedOptions = (container) => _.values(_.omit(container, 'otherDetail'))

const getOtherOption = (container) => {
  const otherDetail = _.get(container, 'otherDetail')
  return _.isEmpty(otherDetail)
    ? {}
    : {
        value: 'other',
        text: otherDetail,
      }
}

const getOptions = (container) => {
  const selectedOptions = getSelectedOptions(container)
  const otherOption = getOtherOption(container)
  return _.map([...selectedOptions, otherOption], (option) => ({
    text: option.text,
    value: option.value,
  }))
}

const getOccupations = (kyc) => {
  const occupationObj = _.get(kyc, 'occupation')
  return getOptions(occupationObj)
}

const getJobIndustries = (kyc) => {
  const jobIndustryObj = _.get(kyc, 'jobIndustry')
  return getOptions(jobIndustryObj)
}

const getIncomeSources = (kyc) => {
  const incomeSourceObj = _.get(kyc, 'incomeSource')
  return getOptions(incomeSourceObj)
}

const getInvestReasons = (kyc) => {
  const investReasonObj = _.get(kyc, 'investReason')
  return getOptions(investReasonObj)
}

const getIncomePerYear = (kyc) => {
  const incomePerYear = _.get(kyc, 'incomePerYear')
  return {
    text: _.get(incomePerYear, 'text'),
    value: _.get(incomePerYear, 'value'),
  }
}

export const kyc = (state) => {
  const kyc = currentApp('KYC')(state)
  return {
    occupations: getOccupations(kyc),
    jobIndustries: getJobIndustries(kyc),
    incomeSources: getIncomeSources(kyc),
    incomePerYear: getIncomePerYear(kyc),
    investReasons: getInvestReasons(kyc),
  }
}
