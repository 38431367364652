import _ from 'lodash'
import { takeLatest, select, put, call, take } from 'redux-saga/effects'
import VALUES from 'core/data-model/constants/values'
import {
  getInsuredDCAProps,
  getRecurringPayment,
  getDCA,
  isCloneATPtoDCA,
  getPolicyId,
  isDCASelected,
  isShowDCASelected,
} from 'e-submission/domain/data-model/form/selectors'

import { hideLoading, setAppValue, showLoading, clonePhoto, fetchSignature } from 'e-submission/apps/actions'

import { getIdentityUser } from 'identity/selectors'

import { getCurrentApp } from 'e-submission/apps/selectors'

export const setDefaultDCA = function*(service, action) {
  const app = yield select(getCurrentApp)

  try {
    yield put.resolve(showLoading())

    yield put(setAppValue('DCA.status', VALUES.DCA_STATUS.INFORMATION))

    const insured = getInsuredDCAProps(app)
    yield put(setAppValue('DCA.firstName', insured.firstName))
    yield put(setAppValue('DCA.lastName', insured.lastName))
    yield put(setAppValue('DCA.title', insured.title))
    yield put(setAppValue('DCA.bank', VALUES.DCA_DEFAULT_BANK))

    if (isCloneATPtoDCA(app)) {
      const ATP = getRecurringPayment(app)
      const accountNumber = _.get(ATP, 'bankBookNumber')
      yield put(setAppValue('DCA.accountNumber', accountNumber))
      yield [put(clonePhoto('bank-book', 'dca-bank-book')), take('LOAD_APP')]
    }
  } catch (err) {
  } finally {
    yield put(hideLoading())
  }
}

export const onUpdateDCA = function*(service, action) {
  const { updateDCA } = service

  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)

  try {
    yield put.resolve(showLoading())

    const dca = getDCA(app)
    const policyId = getPolicyId(app)

    const params = {
      ...dca,
      policyId,
    }

    yield call(updateDCA, user, params)
    yield [put(fetchSignature()), take('HIDE_LOADING')]
    yield put(setAppValue('DCA.status', VALUES.DCA_STATUS.UNSIGHED))
  } catch (err) {
    console.error(err)
  } finally {
    yield put(hideLoading())
  }
}

export const onSignedDCA = function*(service, history, action) {
  const { signedDCA } = service

  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)

  try {
    yield put.resolve(showLoading())

    const dca = getDCA(app)
    const policyId = getPolicyId(app)

    const params = {
      ...dca,
      policyId,
    }

    yield call(signedDCA, user, params)
    yield put(setAppValue('DCA.status', VALUES.DCA_STATUS.SIGNED))
    history.replace(history.location.pathname.replace('/DCA', ''))
  } catch (err) {
    console.error(err)
  } finally {
    yield put(hideLoading())
  }
}

export const onCleanDCA = function*(service, history, action) {
  const { cleanDCA } = service

  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)

  try {
    const isCleanDCA = !isDCASelected(app) && isShowDCASelected(app)
    if (isCleanDCA) {
      const policyId = getPolicyId(app)
      yield call(cleanDCA, user, { policyId })
    }
  } catch (err) {
    console.error(err)
  }
}

export default function*(service, history) {
  yield [takeLatest('SET_DEFAULT_DCA', setDefaultDCA, service)]
  yield [takeLatest('UPDATE_DCA', onUpdateDCA, service)]
  yield [takeLatest('SIGNED_DCA', onSignedDCA, service, history)]
  yield [takeLatest('CLEAN_DCA', onCleanDCA, service, history)]
}
