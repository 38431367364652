import VALUES from 'core/data-model/constants/values'
import { getRiskiestOccupation, getRiskiestOccupationFactorForRiders } from 'core/service/insured'
import {
  getOccupationForRidersInTypeArray,
  getRiders,
  mergeRiderArray,
} from 'e-submission/sagas/policy-payload/rider-selector'
import { modelFactorToPaymentMode } from './coverageplan'
import { currentQQ } from './primitive-selector'

export const getDisplayProductQuery = (state) => {
  const selectedDisplayProduct = currentQQ('selectedDisplayProduct')(state)
  return {
    code:
      selectedDisplayProduct.category === VALUES.MRTA
        ? selectedDisplayProduct.basicPlanCode
        : selectedDisplayProduct.code,
    type: selectedDisplayProduct.type,
  }
}

export const getNatureOfDutyCodes = (state) => {
  return [currentQQ('insured.occupation.natureOfDuty.code')(state)]
}

const getPensionType = (state) => {
  return currentQQ('coveragePlan.pensionType')(state)
}

const getCategoryForDisplayProduct = (state) => {
  return currentQQ('selectedDisplayProduct.category')(state)
}

export const coveragePlanMRTA = async (state) => {
  const sumAssured = currentQQ('coveragePlan.selectedBasicPlan.sumAssured.value')(state)
  const sumAssuredContract = currentQQ('biProps.sumAssuredContract')(state)

  const selectedModelFactorID = currentQQ('coveragePlan.selectedModelFactorID')(state)
  const paymentMode = modelFactorToPaymentMode(selectedModelFactorID)
  const riders = getRiders(state)
  const displayProductQuery = getDisplayProductQuery(state)
  const natureOfDutyCodes = getNatureOfDutyCodes(state)
  const occupationFactor = await getRiskiestOccupation(displayProductQuery, natureOfDutyCodes)
  const riskiestOccupationFactorForRiders = await getRiskiestOccupationFactorForRiders(
    displayProductQuery,
    natureOfDutyCodes,
    riders
  )

  let coveragePlanPayload = {
    basicPlan: {
      sumAssured,
      sumAssuredContract,
      occupationFactor,
    },
    paymentMode,
    riders: mergeRiderArray(riders, getOccupationForRidersInTypeArray(riskiestOccupationFactorForRiders)),
  }

  if (
    getCategoryForDisplayProduct(state) === VALUES.RETIREMENT ||
    getCategoryForDisplayProduct(state) === VALUES.BUMNAN_READY
  ) {
    Object.assign(coveragePlanPayload, { pensionType: getPensionType(state) })
  }
  return coveragePlanPayload
}

export const coveragePlan = async (state) => {
  const sumAssured = currentQQ('coveragePlan.selectedBasicPlan.sumAssured.value')(state)
  const selectedModelFactorID = currentQQ('coveragePlan.selectedModelFactorID')(state)
  const paymentMode = modelFactorToPaymentMode(selectedModelFactorID)
  const riders = getRiders(state)
  const displayProductQuery = getDisplayProductQuery(state)
  const natureOfDutyCodes = getNatureOfDutyCodes(state)
  const occupationFactor = await getRiskiestOccupation(displayProductQuery, natureOfDutyCodes)
  const riskiestOccupationFactorForRiders = await getRiskiestOccupationFactorForRiders(
    displayProductQuery,
    natureOfDutyCodes,
    riders
  )

  let coveragePlanPayload = {
    basicPlan: {
      sumAssured,
      occupationFactor,
    },
    paymentMode,
    riders: mergeRiderArray(riders, getOccupationForRidersInTypeArray(riskiestOccupationFactorForRiders)),
  }

  if (
    getCategoryForDisplayProduct(state) === VALUES.RETIREMENT ||
    getCategoryForDisplayProduct(state) === VALUES.BUMNAN_READY
  ) {
    Object.assign(coveragePlanPayload, { pensionType: getPensionType(state) })
  }
  return coveragePlanPayload
}
