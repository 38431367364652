import { createSelector } from 'reselect'
import { currentApp, currentQQ, fromBuddhistToChristian, when } from './primitive-selector'
import { getPayerGender, getPayerMainOccupation, getPayerOtherOccupation } from '../../apps/selectors'
import { health } from './health-selector'
import { addPrefixToTambol, addPrefixToAmphur } from '../utils'
import { flow, constant, isEqual } from 'lodash/fp'
import _ from 'lodash'
import { getToggles } from 'quick-quote/feature-toggles'

const payerObj = currentApp('payer')
const title = currentApp('payer.title.text')
const firstName = currentApp('payer.firstName')
const lastName = currentApp('payer.lastName')
const idNo = currentApp('payer.idNo')
const idType = currentApp('payer.idType')
const nationality = currentApp('payer.nationality')
const maritalStatus = currentApp('payer.maritalStatus.value')
const fatca = currentApp('payer.FATCA')
const birthdate = createSelector([currentQQ('insured.payer.birthdate.value')], fromBuddhistToChristian)
const age = flow(currentQQ('insured.payer.age'), (ageObject) =>
  ageObject.unit === 'year' ? `${ageObject.value}` : '0'
)
const incomePerMonth = currentApp('payer.mainOccupationIncomePerMonth')
const isWeightChangedRecently = currentApp('payer.isWeightChangedRecently')

export const weightChangedRecently = createSelector(
  [isWeightChangedRecently, currentApp('payer.weightChangedRecently')],
  (isWeightChangedRecently, weightChangedRecently) =>
    isWeightChangedRecently === true ? weightChangedRecently : undefined
)

const addressNumberMoobanArkan = currentApp('payer.payerAddress.numberMoobanArkan')
const addressNumberMoobanArkan2 = currentApp('payer.payerAddress.numberMoobanArkan2')
const addressProvince = currentApp('payer.payerAddress.province')
const addressAmphur = currentApp('payer.payerAddress.amphur.text')
const addressTambol = currentApp('payer.payerAddress.tambol.text')
const addressPostCode = currentApp('payer.payerAddress.postCode.value')
const relationshipToInsured = currentApp('payer.relationshipToInsured')

const motorcycleUsage = currentApp('payer.motorcycleUsage.value')
const otherOccupationIncomePerMonth = currentApp('payer.otherOccupationIncomePerMonth')

export const healthInfo = (state) => health(currentApp('payer')(state))

const oneLineAddress = createSelector(
  [addressNumberMoobanArkan, addressAmphur, addressTambol, addressProvince, addressPostCode],
  (numberMoobanArkan, amphur, tambol, province, postCode) => ({
    numberMoobanArkan,
    amphur: addPrefixToAmphur(amphur, province),
    tambol: addPrefixToTambol(tambol, province),
    province,
    postCode,
  })
)

const twoLinesAddress = createSelector(
  [addressNumberMoobanArkan, addressNumberMoobanArkan2, addressAmphur, addressTambol, addressProvince, addressPostCode],
  (numberMoobanArkan, numberMoobanArkan2, amphur, tambol, province, postCode) => ({
    numberMoobanArkan,
    numberMoobanArkan2,
    amphur: addPrefixToAmphur(amphur, province),
    tambol: addPrefixToTambol(tambol, province),
    province,
    postCode,
  })
)

export const address = createSelector([oneLineAddress, twoLinesAddress], (oneLineAddress, twoLinesAddress) => {
  return getToggles().ENABLE_2_LINES_ADDRESS ? twoLinesAddress : oneLineAddress
})
const officeAddressNumberMoobanArkan = currentApp('payer.officeAddress.numberMoobanArkan')
const officeAddressNumberMoobanArkan2 = currentApp('payer.officeAddress.numberMoobanArkan2')
const officeAddressProvince = currentApp('payer.officeAddress.province')
const officeAddressAmphur = currentApp('payer.officeAddress.amphur.text')
const officeAddressTambol = currentApp('payer.officeAddress.tambol.text')
const officeAddressPostCode = currentApp('payer.officeAddress.postCode.value')
const taxCertConsent = currentApp('payer.taxCertConsent')
const taxCertId = currentApp('payer.idNo.taxId')

const oneLineOfficeAddress = createSelector(
  [
    officeAddressNumberMoobanArkan,
    officeAddressAmphur,
    officeAddressTambol,
    officeAddressProvince,
    officeAddressPostCode,
  ],
  (numberMoobanArkan, amphur, tambol, province, postCode) => ({
    numberMoobanArkan,
    amphur: addPrefixToAmphur(amphur, province),
    tambol: addPrefixToTambol(tambol, province),
    province,
    postCode,
  })
)

const twoLinesOfficeAddress = createSelector(
  [
    officeAddressNumberMoobanArkan,
    officeAddressNumberMoobanArkan2,
    officeAddressAmphur,
    officeAddressTambol,
    officeAddressProvince,
    officeAddressPostCode,
  ],
  (numberMoobanArkan, numberMoobanArkan2, amphur, tambol, province, postCode) => ({
    numberMoobanArkan,
    numberMoobanArkan2,
    amphur: addPrefixToAmphur(amphur, province),
    tambol: addPrefixToTambol(tambol, province),
    province,
    postCode,
  })
)

export const officeAddress = createSelector(
  [oneLineOfficeAddress, twoLinesOfficeAddress],
  (oneLineOfficeAddress, twoLinesOfficeAddress) => {
    return getToggles().ENABLE_2_LINES_ADDRESS ? twoLinesOfficeAddress : oneLineOfficeAddress
  }
)

// Need to be match with: /e-submission/domain/data-model/form/personalSection.js
export const id = createSelector([idNo, idType], (idNo, idType) => {
  switch (idType.value) {
    case 'CITIZEN_ID':
    case 'GOV_ID':
      return idNo['citizenId']
    case 'PASSPORT':
      return idNo['passport']
    default:
      return ''
  }
})

export const payer = (state) => {
  if (!payerObj(state)) {
    return {}
  }
  return createSelector(
    [
      id,
      idType,
      title,
      firstName,
      lastName,
      nationality,
      getPayerGender,
      maritalStatus,
      birthdate,
      age,
      relationshipToInsured,
      incomePerMonth,
      getPayerMainOccupation,
      healthInfo,
      address,
      otherOccupationIncomePerMonth,
      getPayerOtherOccupation,
      motorcycleUsage,
      officeAddress,
      taxCertConsent,
      taxCertId,
      fatca,
    ],
    (
      id,
      idType,
      title,
      firstName,
      lastName,
      nationality,
      gender,
      maritalStatus,
      birthdate,
      age,
      relationshipToInsured,
      incomePerMonth,
      occupation,
      healthInfo,
      address,
      otherOccupationIncomePerMonth,
      otherOccupation,
      motorcycleUsage,
      officeAddress,
      taxCertConsent,
      taxCertId,
      fatca
    ) => ({
      id,
      idType,
      title,
      firstName,
      lastName,
      nationality,
      gender,
      maritalStatus,
      birthdate,
      age: age.toString(),
      relationshipToInsured,
      occupation: {
        ..._.get(occupation, 'th'),
        occupationClass: _.get(occupation, 'occupationClass'),
        natureOfDutyCode: _.get(occupation, 'natureOfDutyCode'),
        incomePerMonth: incomePerMonth,
      },
      officeAddress,
      motorcycleUsage: when(isEqual('yes'), constant('Y'), constant('N'))(motorcycleUsage),
      otherOccupation: {
        occupationClass: _.get(otherOccupation, 'occupationClass'),
        natureOfDutyCode: _.get(otherOccupation, 'natureOfDutyCode'),
        incomePerMonth: otherOccupationIncomePerMonth,
        ..._.get(otherOccupation, 'th'),
      },
      healthInfo,
      address,
      taxCertConsent,
      taxCertId,
      FATCA: fatca,
    })
  )(state)
}
